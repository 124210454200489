import React, { Suspense, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import cx from "classnames";
import totalVolumnIc from "img/analytics/totalVolumn.svg";
import totalFeeIc from "img/analytics/totalFee.svg";
import totalUserIc from "img/analytics/totalUser.svg";
import openInterestIc from "img/analytics/openInterest.svg";
import mlpPoolIc from "img/analytics/mlpPool.svg";

import totalVolumnLightIc from "img/analytics/totalVolumnLight.svg";
import totalFeeLightIc from "img/analytics/totalFeeLight.svg";
import totalUserLightIc from "img/analytics/totalUserLight.svg";
import openInterestLightIc from "img/analytics/openInterestLight.svg";
import mlpPoolLightIc from "img/analytics/mlpPoolLight.svg";
import { useInView } from "react-intersection-observer";
import { useLastBlock, useLastSubgraphBlock } from "dataProvider";
import DateRangeSelect from "./DateRangeSelect";
import {
  yaxisFormatter,
  tooltipLabelFormatter,
  tooltipFormatter,
  YAXIS_WIDTH,
  COLORS,
  LIGHT_COLORS,
  formatNumberStats,
  yaxisFormatterNumber1,
  tooltipLabelFormatterUnits,
  tooltipFormatterNumber2,
} from "lib/helper";
import { useChainId } from "lib/chains";
import { BASE, FANTOM, OP, SUPPORTED_V2_CHAINS, getExplorerUrl, getSimpleChainName } from "config/chains";
import { useThemeContext } from "contexts/ThemeProvider";
import useAnalyticsOverview from "hooks/analyticsV3/useAnalyticsOverview";
import VolumeChartMerged from "pages/Analytics/components/VolumeChartMerged";
import FeesBreakdownChart from "pages/Analytics/components/FeesBreakdownChart";
import MergeAnalyticsChart from "./MergeAnalyticsChart";
import { commify } from "ethers/lib/utils";

const TotalValueLocked = React.lazy(() => import("./Overview/TotalValueLocked"));
const OpenInterest = React.lazy(() => import("./Overview/OpenInterest"));
const UserReport = React.lazy(() => import("./Overview/UserReport"));
const TraderProfit = React.lazy(() => import("./Overview/TraderProfit"));
const MLPReport = React.lazy(() => import("./Overview/MLPReport"));
const OtherReport = React.lazy(() => import("./Overview/OtherReport"));
const MSLPReport = React.lazy(() => import("./Overview/MSLPReport"));

const dateRangeOptions = [
  {
    label: "Last Month",
    id: 1,
  },
  {
    label: "Last 2 Months",
    id: 2,
    isDefault: true,
  },
  // {
  //   label: "Last 3 Months",
  //   id: 3,
  // },
  {
    label: "All time",
    id: 4,
  },
];
const getFromtimestamp = (day) => {
  const now = new Date(day);
  const startOfDayUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));

  const timestamp = startOfDayUTC.getTime();
  return timestamp / 1000;
};
export default function Overview() {
  const { chainId } = useChainId();
  const DEFAULT_GROUP_PERIOD = 86400;
  const [groupPeriod] = useState(DEFAULT_GROUP_PERIOD);

  const { isLightTheme, lightThemeClassName } = useThemeContext();

  const [dataRange, setDataRange] = useState({
    fromValue: moment().subtract(2, "months").toDate(),
    toValue: null,
  });
  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    setDataRange({ fromValue: start, toValue: end });
  };
  const getCurrentChain = () => {
    return chainId === FANTOM ? "fantom" : chainId === OP ? "optimism" : chainId === BASE ? "base" : "arbitrum";
  };
  const endTimeOfDay = () => {
    const endOfDay = new Date();
    endOfDay.setUTCHours(23, 59, 59, 999);

    const timestamp = Math.floor(endOfDay.getTime() / 1000);
    return timestamp;
  };
  const endTimeOfDayByTime = (time) => {
    const date = new Date(time);
    date.setUTCHours(23, 59, 59, 999);

    const timestamp = Math.floor(date.getTime() / 1000);
    return timestamp;
  };
  const from = dataRange.fromValue ? getFromtimestamp(dataRange.fromValue) : undefined;
  const to = dataRange.toValue ? endTimeOfDayByTime(+new Date(dataRange.toValue)) : endTimeOfDay();
  const [lastSubgraphBlock, , lastSubgraphBlockError] = useLastSubgraphBlock(getCurrentChain());
  const [lastBlock] = useLastBlock();
  const isObsolete = lastSubgraphBlock && lastBlock && lastBlock.timestamp - lastSubgraphBlock.timestamp > 3600;

  const { data, loading } = useAnalyticsOverview(getSimpleChainName(chainId), from, to);

  return (
    <Wrapper className={`analytic-container analytic-container-merged`}>
      <HeadWrapper>
        <div className="page-title-block">
          {lastSubgraphBlock && lastBlock && (
            <p className={cx("page-description", { warning: isObsolete })}>
              {isObsolete && "Data is obsolete. "}
              Updated {moment(lastSubgraphBlock.timestamp * 1000).fromNow()}
              &nbsp;at block{" "}
              <a rel="noreferrer" target="_blank" href={`${getExplorerUrl(chainId)}/block/${lastSubgraphBlock.number}`}>
                {lastSubgraphBlock.number}
              </a>
            </p>
          )}
          {lastSubgraphBlockError && (
            <p className="page-description warning">Subgraph data is temporarily unavailable.</p>
          )}
        </div>
        <div className="form">
          <DateRangeSelect
            options={dateRangeOptions}
            startDate={dataRange.fromValue}
            endDate={dataRange.toValue}
            onChange={onDateRangeChange}
          />
        </div>
      </HeadWrapper>
      <div className="chart-grid" style={{ width: "100%" }}>
        <StatsWrap className="stat-overview-wrapper">
          <div className="chart-cell stats">
            <>
              <img src={isLightTheme ? totalVolumnLightIc : totalVolumnIc} alt="img" />
              <div>
                <div className="total-stat-label">Total Volume</div>
                {loading ? (
                  <div className="skeleton-box" style={{ height: "25px", width: "80px" }} />
                ) : (
                  <div className="total-stat-value">
                    {formatNumberStats(data?.volume?.total || 0, { currency: true })}
                    <span
                      className={`total-stat-delta ${
                        data?.volume?.delta && data?.volume?.delta > 0 ? "plus" : "minus"
                      }`}
                      title="Change since previous day"
                    >
                      {data?.volume?.delta && data?.volume?.delta > 0 ? "+" : ""}
                      {formatNumberStats(data?.volume?.delta || 0, { currency: true })}
                    </span>
                  </div>
                )}
              </div>
              {SUPPORTED_V2_CHAINS.includes(chainId) && (
                <div className="version">
                  <div className="version-box">
                    <span className="version-name">V1</span>
                    <span className="version-value">
                      {loading ? (
                        <div className="skeleton-box" style={{ height: "25px", width: "60px" }} />
                      ) : (
                        formatNumberStats(data?.volume?.v1 || 0, { currency: true })
                      )}
                    </span>
                  </div>
                  {SUPPORTED_V2_CHAINS.includes(chainId) && (
                    <div className="version-box">
                      <p className="version-name">V2</p>
                      <span className="version-value">
                        {loading ? (
                          <div className="skeleton-box" style={{ height: "25px", width: "60px" }} />
                        ) : (
                          formatNumberStats(data?.volume?.v2 || 0, { currency: true })
                        )}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </>
          </div>

          <div className="chart-cell stats">
            <>
              {" "}
              <img src={isLightTheme ? mlpPoolLightIc : mlpPoolIc} alt="img" />
              <div>
                <div className="total-stat-label">Total Value Locked</div>
                {loading ? (
                  <div className="skeleton-box" style={{ height: "25px", width: "80px" }} />
                ) : (
                  <div className="total-stat-value">
                    {formatNumberStats(data?.mlp?.total || 0, { currency: true })}
                    <span
                      className={`total-stat-delta ${data?.mlp?.delta && data?.mlp?.delta > 0 ? "plus" : "minus"}`}
                      title="Change since previous day"
                    >
                      {data?.mlp?.delta && data?.mlp?.delta > 0 ? "+" : ""}
                      {formatNumberStats(data?.mlp?.delta || 0, { currency: true })}
                    </span>
                  </div>
                )}
              </div>
              {SUPPORTED_V2_CHAINS.includes(chainId) && (
                <div className="version">
                  <div className="version-box">
                    <span className="version-name">V1</span>
                    <span className="version-value">
                      {loading ? (
                        <div className="skeleton-box" style={{ height: "25px", width: "60px" }} />
                      ) : (
                        formatNumberStats(data?.mlp?.v1 || 0, { currency: true })
                      )}
                    </span>
                  </div>
                  <div className="version-box">
                    <p className="version-name">V2</p>
                    <span className="version-value">
                      {loading ? (
                        <div className="skeleton-box" style={{ height: "25px", width: "60px" }} />
                      ) : (
                        formatNumberStats(data?.mlp?.v2 || 0, { currency: true })
                      )}
                    </span>
                  </div>
                </div>
              )}
            </>
          </div>
          <div className="chart-cell stats">
            <>
              <img src={isLightTheme ? totalFeeLightIc : totalFeeIc} alt="img" />
              <div>
                <div className="total-stat-label">Total Fees</div>
                {loading ? (
                  <div className="skeleton-box" style={{ height: "25px", width: "80px" }} />
                ) : (
                  <div className="total-stat-value">
                    {formatNumberStats(data?.fees?.total || 0, { currency: true })}
                    <span
                      className={`total-stat-delta ${data?.fees?.delta && data?.fees?.delta > 0 ? "plus" : "minus"}`}
                      title="Change since previous day"
                    >
                      {data?.fees?.delta && data?.fees?.delta > 0 ? "+" : ""}
                      {formatNumberStats(data?.fees?.delta || 0, { currency: true })}
                    </span>
                  </div>
                )}
              </div>
              {SUPPORTED_V2_CHAINS.includes(chainId) && (
                <div className="version">
                  <div className="version-box">
                    <span className="version-name">V1</span>

                    <span className="version-value">
                      {loading ? (
                        <div className="skeleton-box" style={{ height: "25px", width: "60px" }} />
                      ) : (
                        formatNumberStats(data?.fees?.v1 || 0, { currency: true })
                      )}
                    </span>
                  </div>
                  <div className="version-box">
                    <p className="version-name">V2</p>
                    <span className="version-value">
                      {loading ? (
                        <div className="skeleton-box" style={{ height: "25px", width: "60px" }} />
                      ) : (
                        formatNumberStats(data?.fees?.v2 || 0, { currency: true })
                      )}
                    </span>
                  </div>
                </div>
              )}
            </>
          </div>
          <div className="chart-cell stats">
            <>
              <img src={isLightTheme ? totalUserLightIc : totalUserIc} alt="img" />
              <div>
                <div className="total-stat-label">Total Users</div>
                {loading ? (
                  <div className="skeleton-box" style={{ height: "25px", width: "80px" }} />
                ) : (
                  <div className="total-stat-value">
                    {formatNumberStats(
                      (data?.users?.usersData?.[data?.users?.usersData?.length - 1].cumulativeNewUserCount || 0),
                      { currency: false }
                    )}
                    <span className="total-stat-delta plus" title="Change since previous day">
                      +
                      {commify(
                        (data?.users?.usersData?.[data?.users?.usersData?.length - 1]?.cumulativeNewUserCount || 0) -
                          (data?.users?.usersData?.[data?.users?.usersData?.length - 2]?.cumulativeNewUserCount || 0)
                      )}
                    </span>
                  </div>
                )}
              </div>
              <div className="version">
                <div className="version-box">
                  <span className="version-name">V1</span>
                  <span className="version-value">
                    {loading ? (
                      <div className="skeleton-box" style={{ height: "25px", width: "60px" }} />
                    ) : (
                      commify(data?.users?.totalV1 || 0)
                    )}
                  </span>
                </div>
                <div className="version-box">
                  <p className="version-name">V2</p>
                  <span className="version-value">
                    {loading ? (
                      <div className="skeleton-box" style={{ height: "25px", width: "60px" }} />
                    ) : (
                      commify(data?.users?.totalV2 || 0)
                    )}
                  </span>
                </div>
              </div>
            </>
          </div>
          <div className="chart-cell stats">
            <>
              <img src={isLightTheme ? openInterestLightIc : openInterestIc} alt="img" />
              <div>
                <div className="total-stat-label">Total Trades</div>
                {loading ? (
                  <div className="skeleton-box" style={{ height: "25px", width: "80px" }} />
                ) : (
                  <div className="total-stat-value">
                    {commify(data?.trades?.total || 0)}
                    <span
                      className={`total-stat-delta ${
                        data?.trades?.delta && data?.trades?.delta > 0
                          ? "plus"
                          : data?.trades?.delta && data?.trades?.delta < 0
                          ? "minus"
                          : ""
                      }`}
                      title="Change since previous day"
                    >
                      {data?.trades?.delta && data?.trades?.delta > 0 ? "+" : ""}
                      {commify(data?.trades?.delta || 0)}
                    </span>
                  </div>
                )}
              </div>
              {SUPPORTED_V2_CHAINS.includes(chainId) && (
                <div className="version">
                  <div className="version-box">
                    <span className="version-name">V1</span>
                    <span className="version-value">
                      {loading ? (
                        <div className="skeleton-box" style={{ height: "25px", width: "60px" }} />
                      ) : (
                        commify(data?.trades?.v1 || 0)
                      )}
                    </span>
                  </div>
                  <div className="version-box">
                    <p className="version-name">V2</p>
                    <span className="version-value">
                      {loading ? (
                        <div className="skeleton-box" style={{ height: "25px", width: "60px" }} />
                      ) : (
                        commify(data?.trades?.v2 || 0)
                      )}
                    </span>
                  </div>
                </div>
              )}
            </>
          </div>
        </StatsWrap>
        <ChartContainer>
          <div className="chart-cell-primary border-right-chart">
            <VolumeChartMerged
              data={data?.chartData || []}
              loading={loading}
              chartHeight={480}
              yaxisWidth={YAXIS_WIDTH}
              xaxisTickFormatter={tooltipLabelFormatter}
              yaxisTickFormatter={yaxisFormatter}
              tooltipLabelFormatter={tooltipLabelFormatter}
              tooltipFormatter={tooltipFormatter}
              isLightTheme={isLightTheme}
            />
          </div>
          <div>
            <div className="chart-cell-primary border-bottom-chart chart-fee-breakdown">
              <FeesBreakdownChart
                data={
                  data?.chartData.map((item) => ({
                    ...item,
                    all: item.allFees,
                  })) || []
                }
                loading={loading}
                chartHeight={206}
                yaxisWidth={YAXIS_WIDTH}
                xaxisTickFormatter={tooltipLabelFormatter}
                yaxisTickFormatter={yaxisFormatter}
                tooltipLabelFormatter={tooltipLabelFormatter}
                tooltipFormatter={tooltipFormatter}
                isLightTheme={isLightTheme}
              />
            </div>
            <div className="chart-cell-primary">
              <MergeAnalyticsChart
                loading={loading}
                height={206}
                title="Users Breakdown"
                data={data?.users?.usersData.map((item) => ({ ...item, all: item.allBreakDown }))}
                truncateYThreshold={6000}
                yaxisDataKey="allBreakDown"
                rightYaxisDataKey="cumulativeBreakdown"
                yaxisTickFormatter={yaxisFormatterNumber1}
                tooltipFormatter={tooltipFormatterNumber2}
                tooltipLabelFormatter={tooltipLabelFormatterUnits}
                items={[
                  { key: "uniqueSwapCount", name: "Swap" },
                  { key: "uniqueMarginCount", name: "Margin Trading" },
                  { key: "mintBurnMLP", name: "Mint & Burn MLP" },
                  { key: "mintBurnMSLP", name: "Mint & Burn MSLP" },
                  {
                    key: "cumulativeBreakdown",
                    name: "Cumulative",
                    type: "Line",
                    yAxisId: "right",
                    strokeWidth: 2,
                  },
                ]}
                type="Composed"
              />
            </div>
          </div>
        </ChartContainer>
      </div>
      {/* <TotalValueLocked data={data} loading={loading} />
      <OpenInterest data={data} loading={loading} /> */}
      <LazyComponent>
        <TotalValueLocked data={data} loading={loading} />
      </LazyComponent>
      <LazyComponent>
        <OpenInterest data={data} loading={loading} />
      </LazyComponent>
      <LazyComponent>
        <UserReport data={data} loading={loading} />
      </LazyComponent>
      <LazyComponent>
        <TraderProfit data={data} loading={loading} from={from} to={to} />
      </LazyComponent>
      <LazyComponent>
        <MLPReport data={data} loading={loading} from={from} to={to} columnLegend={true} />
      </LazyComponent>
      <LazyComponent>
        <MSLPReport data={data} loading={loading} columnLegend={true} hideLehender={true} />
      </LazyComponent>
      <LazyComponent>
        <OtherReport from={from} to={to} groupPeriod={groupPeriod} dataVolume={data} loading={loading} />
      </LazyComponent>
    </Wrapper>
  );
}
function LazyComponent({ children }) {
  const { ref, inView } = useInView({
    triggerOnce: true, // Load the component only once when it comes into view
    threshold: 0.1, // Percentage of the component that must be visible
  });

  return (
    <div ref={ref} style={{ width: "100%" }}>
      {inView ? (
        <Suspense fallback={<div className="skeleton-box" style={{ width: "100%", height: "60px" }} />}>
          {children}
        </Suspense>
      ) : (
        <div className="skeleton-box" style={{ width: "100%", height: "50px", marginTop: "12px" }} />
      )}
    </div>
  );
}
const HeadWrapper = styled.div`
  .form {
    @media screen and (max-width: 991px) {
      display: flex;
      justify-content: flex-end;
    }
    @media (max-width: 767px) {
      width: 100%;
      > div {
        width: inherit;
      }
    }
  }
  .date-range-selector-wrapper .rdrDayNumber span {
    color: #fff;
  }
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;
const ChartContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  border-radius: 16px;
  overflow: hidden;

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .recharts-tooltip-wrapper .recharts-default-tooltip {
    background-color: #353651 !important;
    box-shadow: 0 3px 6px #0000005c;
    border: 1px solid #484b6e !important;
    border-radius: 4px;
  }
  .chart-header {
    display: flex;
    justify-content: space-between;
  }
  .chart-control-checkbox {
    width: fit-content;
    background: #333;
    color: #aaa;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 8px;
    padding: 8px 7px;

    font-weight: 700;

    border-radius: 4px;
    transition: background-color 0.1s, color 0.1s;
    font-size: 14px;
  }
  .chart-control-checkbox.active {
    background: #609ade;
    color: #fff;
  }

  .recharts-legend-item-text {
    display: inline-block;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  .recharts-legend-item {
    padding-bottom: 0;
  }

  .chart-cell {
    padding: 24px;
    position: relative;
    background: #191b2e;
    border-radius: 16px;
    .chart-header {
      h3 {
        margin-top: 0;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        a {
          margin-left: 8px;
        }
      }
    }

    @media (max-width: 767px) {
      padding: 16px;
    }
  }

  .border-right-chart {
    border-right: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
  }
  .border-bottom-chart {
    border-bottom: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
  }
`;
const StatsWrap = styled.div`
  margin: 24px 0px;
  border-radius: 16px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  gap: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    margin-bottom: 16px;
    /* padding: 16px; */
    margin-bottom: 16px;
    gap: 16px;
  }

  .stats {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    border-radius: 16px;
    background: linear-gradient(180deg, #0e1d18 0%, #181c26 100%);
  }

  .chart-cell {
    @media screen and (max-width: 1023px) {
      > div {
        display: flex;
        justify-content: space-between;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        .total-stat-value {
          text-align: right;
        }
      }
    }

    img {
      width: 60px;
      height: 60px;
      margin-right: 8px;
    }
    .total-stat-label {
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
    .total-stat-value {
      margin-top: 4px;
      font-weight: 700;
      line-height: 140%;
      font-size: 24px;
      font-style: normal;
      .total-stat-delta {
        margin-top: 4px;
        display: block;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;

        color: var(--Functional-Green, #03f5ae);

        @media (max-width: 1023px) {
          text-align: left;
        }

        @media (max-width: 767px) {
          display: inline;
          margin-top: 0;
        }

        &.minus {
          color: rgb(246, 71, 93);
        }
      }
    }

    @media (max-width: 767px) {
      .total-stat-value {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 4px;

        > span {
          font-size: 12px !important;
        }
      }
    }
    .version {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Base-Unit-XS-8, 8px);
      width: 100%;

      align-self: stretch;
    }
    .version-box {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
    }
    .version-name {
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));

      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }
    .version-value {
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
    }
  }
`;
const Wrapper = styled.div`
  flex-direction: column;
  padding-bottom: 200px;
  .merged-chart-container {
    display: flex;
    gap: 12px;
    .merge-chart-content {
      flex-grow: 1;
      width: unset;
    }
    .custom-legend {
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;
      align-items: flex-end;
      flex-shrink: 0;
      min-width: 50px;
      .legend-item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: var(--Border_radius, 4px);
        width: fit-content !important;
        .legend-name {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          max-width: 150px; /* Set the maximum width */
          white-space: nowrap; /* Ensure the text doesn't wrap to the next line */
          overflow: hidden; /* Hide the overflow text */
          text-overflow: ellipsis; /* Add ellipsis (...) to the end of the text */
        }
        .legend-color {
          width: 8px !important;
          height: 8px !important;
          border-radius: 100px;
        }
      }
    }
  }
  .legend-column {
    flex-direction: column !important;
    padding-bottom: 20px;
    gap: 8px;
    .custom-legend {
      width: 100%;
      flex-direction: row !important;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      .legend-item {
        justify-content: flex-start;
        flex-direction: row-reverse;
      }
    }
  }

  .chart-cell-primary {
    /* padding: 24px; */
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;
    position: relative;
    z-index: 5;
    .recharts-tooltip-wrapper {
      top: -15px !important;
    }
    .chart-header {
      h3 {
        margin-top: 24px;
        padding-left: 12px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        a {
          margin-left: 8px;
        }
      }
    }

    @media (max-width: 767px) {
      padding: 16px;
    }
    background-color: #14161f;
  }
  .chart-fee-breakdown {
    .recharts-tooltip-wrapper {
      top: -60px !important;
    }
  }
  .rounded {
    border-radius: 16px;
  }
  .react-dropdown-select-dropdown-handle {
    display: none;
  }
  .react-dropdown-select {
    border: 1px solid #e6e6f7 !important;
    box-sizing: border-box;
    position: relative;
    display: flex;
    width: 100%;
    border-radius: 2px;
    padding: 2px 5px;
    flex-direction: row;
    direction: ltr;
    align-items: center;
    cursor: pointer;
    min-height: 36px;
    pointer-events: all;
    background-color: #fff;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .date-range-items {
    overflow: auto;
    min-height: 10px;
    max-height: 200px;
  }
  .react-dropdown-select {
    background: #191b2e;
    border: 1px solid #ffffff1a !important;
    border-radius: 8px;
    width: 100%;
    padding: 12px 16px;
  }
  .date-range-item {
    display: flex;
    margin: 0.5rem;
    padding: 0.5rem;
    align-items: baseline;
  }
  .rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    padding-top: 10px;
    box-sizing: inherit;
    display: flex;
    justify-content: space-between;
  }
  .rdrDay {
    box-sizing: inherit;
    width: calc(100% / 7);
    position: relative;
    font: inherit;
    cursor: pointer;
  }
  .rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .date-range-selector-wrapper .rdrMonthAndYearPickers select {
    color: #fff;
    background: #16182e;
  }
  .date-range-selector-wrapper .rdrCalendarWrapper {
    background-color: #16182e;
  }
  .rdrCalendarWrapper {
    color: #000;
    font-size: 12px;
  }
  .react-dropdown-select-dropdown {
    background-color: #101124 !important;
    border: 1px solid #ffffff14 !important;
    position: absolute;
    top: 43px;
    right: 0;
    left: unset !important;
    border: 1px solid #ccc;
    width: 348px;
    padding: 0;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    overflow: auto;
    z-index: 9;
    max-height: 475px !important;
    width: fit-content !important;
  }
  .date-range-custom {
    color: #000;
    margin: 0.5rem;
    text-align: center;
  }
  .page-description {
    margin-bottom: 0;
    color: rgb(173, 171, 171);

    a {
      color: rgb(173, 171, 171);
    }
  }
  .custom-tooltip {
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px #0003;
    gap: 4px;
    .item-group {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  &.theme--light {
    .recharts-cartesian-axis-ticks {
      tspan {
        fill: #0d1a16b2;
      }
    }
  }
  .title {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

// const CustomTooltipWrapper = styled.div``;
