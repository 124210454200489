import SettingsManager from "abis/SettingsManager.json";
import cx from "classnames";
import { getTokenSymbolFromString } from "domain/tokens";
import { BigNumber } from "ethers";
import editIcon from "img/trade/edit.svg";
import { contractFetcher } from "lib/contracts";
import { PRECISION, importImage } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import { useMemo, useState } from "react";
import useSWR from "swr";

import { ARBITRUM } from "config/chains";
import { getContract } from "config/contracts";
import { addTrailingStop, cancelPendingOrder, cancelTriggerOrder, editTriggerOrder } from "domain/legacy";
import moment from "moment";
import PaginationV2 from "pages/Analytics/components/PaginationV2";
import EditTakeProfit from "./EditTakeProfit";
import EditTralingStop from "./EditTralingStop";

const PAGE_SIZE = 10;

export default function OrderListNew(props) {
  const { positions, library, setPendingTxns, chainId, marketAsssets, marketTokensInfo, myOrders, loading } = props;

  const [page, setPage] = useState(1);
  const [selectedPosition, setSelectedPosition] = useState(undefined);
  const [isEditTakeProfitModalOpen, setIsEditTakeProfitModalOpen] = useState(false);
  const [isEditTralingStopModalOpen, setIsEditTralingStopModalOpen] = useState(false);
  const [isSectionConfirming, setIsSectionConfirming] = useState(false);

  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const { data: triggerGasFee } = useSWR(["ExChange:triggerGasFee", chainId, settingsManagerAddress, "triggerGasFee"], {
    fetcher: contractFetcher(library, SettingsManager),
  });
  const handleCancelTrigger = (position) => {
    // debugger
    const posId = position.posId;
    const orderId = position?.trigger?.id?.split("-")[1];
    cancelTriggerOrder(chainId, library, posId, orderId, {
      value: triggerGasFee,
      setPendingTxns,
      sentMsg: `Cancel trigger submitted.`,
      failMsg: `Cancel trigger failed.`,
      successMsg: "Cancelled trigger successfully",
      hideSuccessMsg: chainId === ARBITRUM,
    })
      .then(async () => {})
      .finally(() => {});
  };
  const handlePendingTrigger = (position) => {
    // debugger
    const posId = position.posId;
    cancelPendingOrder(chainId, library, posId, {
      value: triggerGasFee,
      setPendingTxns,
      sentMsg: `Cancel trigger submitted.`,
      failMsg: `Cancel trigger failed.`,
      successMsg: "Cancelled trigger successfully",
      hideSuccessMsg: chainId === ARBITRUM,
    })
      .then(async () => {})
      .finally(() => {});
  };
  const handleEditTriggerOrder = (position, _orderId, _isTP, _price, _amountPercent) => {
    const _posId = position.posId;
    setIsSectionConfirming(true);
    editTriggerOrder(chainId, library, _posId, _orderId, _isTP, _price, _amountPercent, {
      // value: BigNumber.from(isTPs.length).mul(triggerGasFee),
      setPendingTxns,
      sentMsg: `Edit TP/SL submitted.`,
      failMsg: `Edit TP/SL`,
      successMsg: "Edited TP/SL successfully",
      hideSuccessMsg: chainId === ARBITRUM,
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsEditTakeProfitModalOpen(false);
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };
  const handleAddTrailingStop = (posId, params) => {
    setIsSectionConfirming(true);
    addTrailingStop(chainId, library, posId, params, {
      value: triggerGasFee,
      setPendingTxns,
      sentMsg: `Edit trailing stop submitted.`,
      failMsg: `Edit trailing stop failed`,
      successMsg: "Edited trailing stop successfully",
      hideSuccessMsg: chainId === ARBITRUM,
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsEditTralingStopModalOpen(false);
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };

  const filterOrders = useMemo(() => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = page * PAGE_SIZE;
    return myOrders?.slice(startIndex, endIndex) || [];
  }, [myOrders, page]);
  const renderLeverage = (position) => {
    if (position.type === "TRIGGER") {
      return `${formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X`;
    } else if (position.type === "PENDDING") {
      const size = BigNumber.from(position.pendingSize);
      const collateral = BigNumber.from(position.pendingCollateral);
      const leverage = size.gt(0)
        ? formatAmount(size.mul(parseValue(1, 30)).div(collateral), 30, 2, false) + "X"
        : null;
      return leverage;
    } else {
      const size = BigNumber.from(position.size);
      const collateral = BigNumber.from(position.collateral);
      const leverage = size.gt(0)
        ? formatAmount(size.mul(parseValue(1, 30)).div(collateral), 30, 2, false) + "X"
        : null;
      return leverage;
    }
  };
  const renderType = (position) => {
    if (position.type === "TRIGGER") {
      return position?.trigger?.isTP ? "Take Profit" : "Stop Loss";
    } else if (position.type === "PENDDING") return position.positionType;
    else return "Trailing Stop";
  };
  const renderPosSize = (position) => {
    let result = { size: 0, quantity: 0 };
    if (position.type === "TRIGGER") {
      const posQty = position.size.gt(0) ? position.size.mul(parseValue(1, 30)).div(position.averagePrice) : 0;
      result = {
        size: formatAmount(position.size, 30, 2, true),
        quantity: formatAmount(posQty, 30, 4, true),
      };
    } else if (position.type === "PENDDING") {
      const posQty = BigNumber.from(position.pendingSize)
        .mul(parseValue(1, 30))
        .div(BigNumber.from(position.positionType === "Limit Order" ? position?.lmtPrice : position?.stpPrice));
      result = {
        size: formatAmount(position.pendingSize, 30, 2, true),
        quantity: formatAmount(posQty, 30, 4, true),
      };
    } else {
      const size = BigNumber.from(position.size);
      const averagePrice = BigNumber.from(position.averagePrice);
      const posQty = size.mul(parseValue(1, 30)).div(averagePrice);
      result = {
        size: formatAmount(size, 30, 2, true),
        quantity: formatAmount(posQty, 30, 4, true),
      };
    }
    return result;
  };
  const renderCollateral = (position) => {
    if (position.type === "TRIGGER") {
      return formatAmount(position.collateral, 30, 2, true);
    } else if (position.type === "PENDDING") return formatAmount(position.pendingCollateral, 30, 2, true);
    else return formatAmount(position.collateral, 30, 2, true);
  };
  const renderParamaters = (position, currentMarket) => {
    let result = { param1: 0, param1Sub: "0", param2: null, param2Sub: "%" };

    if (position.type === "TRIGGER") {
      const conditionQuantity = Number(position.trigger.amountPercent / 1000);
      result = {
        ...result,
        param1: `${formatAmount(position?.trigger?.price, 30, currentMarket?.decimals || 2, true)}`,
        param1Sub: position.trigger.isTP ? "TP" : "SL",
        param2: conditionQuantity?.toString().includes(".") ? limitDecimals(conditionQuantity, 2) : conditionQuantity,
      };
    } else if (position.type === "PENDDING") {
      result = {
        ...result,
        param1: formatAmount(
          position.positionType === "Limit Order" ? position?.lmtPrice : position?.stpPrice,
          30,
          currentMarket?.decimals || 2,
          true
        ),
        param1Sub: position.positionType === "Limit Order" ? "LP" : "SP",
        param2:
          position.positionType === "Stop Limit"
            ? formatAmount(position?.lmtPrice, 30, currentMarket?.decimals || 2, true)
            : null,
        param2Sub: position.positionType === "Stop Limit" ? "LP" : null,
      };
    } else {
      const size = BigNumber.from(position.size);
      const sizeDelta = BigNumber.from(position.sizeDelta);
      const amountPercent = sizeDelta.mul(parseValue(100, 30)).div(size);
      result = {
        ...result,
        param1:
          position.stepType == 0
            ? formatAmount(BigNumber.from(position.stepAmount), 30, currentMarket?.decimals || 2, true)
            : formatAmount(BigNumber.from(position.stepAmount), 3, currentMarket?.decimals || 2, true),
        param1Sub: position.stepType == 1 ? "T%" : "TA",
        param2: size.eq(sizeDelta) ? 100 : formatAmount(amountPercent, 30, 2, true),
      };
    }
    return result;
  };
  const handleCancelOrder = (position) => {
    if (position.type === "TRIGGER") {
      handleCancelTrigger(position);
    } else {
      handlePendingTrigger(position);
    }
  };
  const handleEditOrder = (position) => {
    if (position.type === "TRIGGER") {
      const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
      const markPrice = BigNumber.from(position?.isLong ? currentMarket?.askPrice || 0 : currentMarket?.bidPrice || 0);
      const symbol = getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol);
      const posQty = position.size.gt(0) ? position.size.mul(parseValue(1, 30)).div(position.averagePrice) : 0;
      const triggerPrice = BigNumber.from(position.trigger.price);
      const completePosition = {
        ...position,
        markPrice,
        symbol,
        posQty,
        triggerPrice,
      };
      setSelectedPosition(completePosition);

      setIsEditTakeProfitModalOpen(true);
    } else {
      const size = BigNumber.from(position.size);
      const sizeDelta = BigNumber.from(position.sizeDelta);
      const averagePrice = BigNumber.from(position.averagePrice);
      const posQty = size.mul(parseValue(1, 30)).div(averagePrice);
      const collateral = BigNumber.from(position.collateral);
      const symbol = getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol);
      const leverage = size.gt(0) ? formatAmount(size.mul(parseValue(1, 30)).div(collateral), 30, 2, false) : null;
      const deltaQuantity = sizeDelta.mul(PRECISION).div(averagePrice);
      const amountPercent = sizeDelta.mul(parseValue(100, 30)).div(size);
      const completedPosition = {
        ...position,
        quantity: posQty,
        averagePrice,
        symbol,
        size,
        sizeDelta,
        collateral,
        leverage,
        deltaQuantity,
        amountPercent,
      };
      setSelectedPosition(completedPosition);
      setIsEditTralingStopModalOpen(true);
    }
  };
  return (
    <div className="PositionsList">
      {isEditTakeProfitModalOpen && (
        <EditTakeProfit
          isVisible={isEditTakeProfitModalOpen}
          position={selectedPosition}
          setIsVisible={setIsEditTakeProfitModalOpen}
          chainId={chainId}
          onConfirm={handleEditTriggerOrder}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {isEditTralingStopModalOpen && (
        <EditTralingStop
          isVisible={isEditTralingStopModalOpen}
          position={selectedPosition}
          setIsVisible={setIsEditTralingStopModalOpen}
          chainId={chainId}
          onConfirm={handleAddTrailingStop}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {myOrders && (
        <div className="Exchange-list small">
          <div>
            {!loading && myOrders.length === 0 && <div className="no-data-record">No open orders</div>}

            {!loading &&
              filterOrders.map((position, index) => {
                const symbol = getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol);
                const dateFormat = "YYYY-MM-DD";
                const timeFormat = "HH:mm:ss";
                const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
                return (
                  <div key={index} className="App-card">
                    <div className="App-card-title">
                      <span className="Exchange-list-title">
                        <img src={importImage("ic_" + symbol?.toLowerCase() + "_24.svg")} alt="" /> {symbol}/USD{" "}
                        <span className="muted">#{position.posId}</span>
                      </span>
                    </div>
                    <div className="App-card-divider" />
                    <div className="App-card-content app-card-position">
                      <div className="leverage-pad">
                        <div>
                          <span
                            className={cx({
                              positive: position.isLong,
                              negative: !position.isLong,
                            })}
                            style={{ color: position.isLong ? "#03f5ae" : "#F6475D" }}
                          >
                            {position.isLong ? `LONG` : `SHORT`}
                          </span>
                        </div>
                        <div
                          className=" leverage-box"
                          style={{
                            color: "#FFC700",
                            backgroundColor: "rgba(255, 199, 0, 0.20)",
                            height: 16,
                            display: "inline-block",
                            opacity: 1,
                            borderRadius: "4px",
                            padding: "0 4px",
                            fontSize: "12px",
                            lineHeight: "15.6px",
                            fontWeight: 400,
                            marginLeft: "4px",
                          }}
                        >
                          {renderLeverage(position)}
                        </div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <span>Created</span>
                        </div>
                        <div>
                          {moment(new Date(position.createdAt * 1000)).format(dateFormat)}
                          <div className="muted fz-12">
                            {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
                          </div>
                        </div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <span>Type</span>
                        </div>
                        <div className="position-list-collateral">{renderType(position)}</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <span>Pos. Size</span>
                        </div>
                        <div>
                          <div>${renderPosSize(position).size}</div>
                          <div className="muted fz-12">
                            {renderPosSize(position).quantity} {symbol}
                          </div>
                        </div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <span>Collateral</span>
                        </div>
                        <div>${renderCollateral(position)}</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <span>Parameters</span>
                        </div>
                        <div>
                          <div>
                            {renderParamaters(position, currentMarket).param1}{" "}
                            <span className="muted">{renderParamaters(position, currentMarket).param1Sub}</span>
                          </div>
                          {renderParamaters(position, currentMarket).param2 && (
                            <div>
                              {renderParamaters(position, currentMarket).param2}{" "}
                              <span className="muted">{renderParamaters(position, currentMarket).param2Sub}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div style={{ marginTop: "1.3rem" }} />
                    <div className="App-card-options">
                      <button
                        className="App-button-option App-card-option"
                        onClick={(e) => handleCancelOrder(position)}
                        // disabled={position.size == 0}
                      >
                        <span>Cancel</span>
                      </button>
                      {position.type !== "PENDDING" && (
                        <button className="PositionDropdown-dots-icon" onClick={() => handleEditOrder(position)}>
                          <img src={editIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
                          Edit Order
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      {loading && (
        <>
          <div className="skeleton-box" style={{ height: "56.19px" }} />
          <div className="skeleton-box" style={{ height: "56.19px", marginTop: 8 }} />
          <div className="skeleton-box" style={{ height: "56.19px", marginTop: 8 }} />
        </>
      )}
      {!loading && (
        <table className={`Exchange-list large App-box ${myOrders.length === 0 ? "no-array-length" : ""}`}>
          <tbody>
            {filterOrders && filterOrders.length > 0 && (
              <tr className="Exchange-list-header">
                <th>
                  <span>Position</span>
                </th>
                <th>
                  <span>Created</span>
                </th>
                <th>
                  <span>Type</span>
                </th>
                <th>
                  <span>Pos. Size</span>
                </th>
                <th>
                  <span>Collateral</span>
                </th>
                <th>
                  <span>Parameters</span>
                </th>
                <th></th>
              </tr>
            )}

            {myOrders.length === 0 && (
              <tr>
                <td colSpan="15">
                  <div className="no-data-record">No open orders</div>
                </td>
              </tr>
            )}
            {filterOrders &&
              filterOrders.length > 0 &&
              filterOrders.map((position, index) => {
                const dateFormat = "YYYY-MM-DD";
                const timeFormat = "HH:mm:ss";
                const symbol = getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol);
                const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
                const markPrice = BigNumber.from(
                  position?.isLong ? currentMarket?.askPrice || 0 : currentMarket?.bidPrice || 0
                );

                if (position.type === "TRIGGER") {
                  const posQty = position.size.gt(0)
                    ? position.size.mul(parseValue(1, 30)).div(position.averagePrice)
                    : 0;
                  const conditionPrice = `${formatAmount(
                    position?.trigger?.price,
                    30,
                    currentMarket?.decimals || 2,
                    true
                  )}`;
                  const conditionQuantity = Number(position.trigger.amountPercent / 1000);
                  const triggerPrice = BigNumber.from(position.trigger.price);
                  const liquidationPrice = position.liquidationPrice;
                  const completePosition = {
                    ...position,
                    markPrice,
                    symbol,
                    posQty,
                    triggerPrice,
                    liquidationPrice,
                  };
                  return (
                    <tr
                      key={index}
                      className="table-content"
                      // onClick={() => handleSelectedPosition(completePosition)}
                    >
                      <td>
                        <div>
                          <img src={importImage("ic_" + symbol?.toLowerCase() + "_24.svg")} alt="tk" />
                          <div className="tk_info">
                            <div className="Exchange-list-title">
                              {symbol}/USD <span className="muted">#{position.posId}</span>
                            </div>
                            <div className="Exchange-list-info-label">
                              <span
                                className={cx({
                                  positive: position.isLong,
                                  negative: !position.isLong,
                                })}
                                style={{ color: position.isLong ? "#03f5ae" : "#F6475D" }}
                              >
                                {position.isLong ? `LONG` : `SHORT`}
                              </span>
                              {position.leverage && (
                                <span
                                  className=" leverage-box"
                                  style={{
                                    color: "#FFC700",
                                    backgroundColor: "rgba(255, 199, 0, 0.20)",
                                    height: 16,
                                    display: "inline-block",
                                    opacity: 1,
                                    borderRadius: "4px",
                                    padding: "0 4px",
                                    fontSize: "12px",
                                    lineHeight: "15.6px",
                                    fontWeight: 400,
                                    marginLeft: "4px",
                                  }}
                                >
                                  {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={`flag ${position.isLong ? "isLong" : "isShort"}`}></div>
                      </td>
                      <td>
                        {moment(new Date(position.createdAt * 1000)).format(dateFormat)}
                        <div className="muted fz-12">
                          {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
                        </div>
                      </td>
                      <td>
                        <div>{position?.trigger.isTP ? "Take Profit" : "Stop Loss"}</div>
                      </td>
                      <td>
                        <div>${formatAmount(position.size, 30, 2, true)}</div>
                        <div className="muted fz-12">
                          {formatAmount(posQty, 30, 4, true)} {symbol}
                        </div>
                      </td>
                      <td>
                        <div>${formatAmount(position.collateral, 30, 2, true)}</div>
                      </td>
                      <td>
                        {conditionPrice} <span className="muted">{position.trigger.isTP ? "TP" : "SL"}</span>
                        <div className="fz12">
                          {conditionQuantity?.toString().includes(".")
                            ? limitDecimals(conditionQuantity, 2)
                            : conditionQuantity}
                          <span className="muted"> %</span>
                        </div>
                      </td>

                      <td style={{ width: "30px" }}>
                        <div className="close-container">
                          <button
                            className="Exchange-list-action"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCancelTrigger(completePosition);
                            }}
                            // disabled={position.size.eq(0)}
                          >
                            Cancel
                          </button>
                          <button
                            className="PositionDropdown-dots-icon"
                            onClick={() => {
                              setSelectedPosition(completePosition);
                              setIsEditTakeProfitModalOpen(true);
                            }}
                          >
                            <img
                              src={editIcon}
                              alt="icon"
                              style={{ width: "16px", height: "16px", maxWidth: "16px" }}
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                } else if (position.type === "PENDDING") {
                  const size = BigNumber.from(position.pendingSize);
                  const collateral = BigNumber.from(position.pendingCollateral);
                  const posQty = size
                    .mul(parseValue(1, 30))
                    .div(
                      BigNumber.from(position.positionType === "Limit Order" ? position?.lmtPrice : position?.stpPrice)
                    );
                  const leverage = size.gt(0)
                    ? formatAmount(size.mul(parseValue(1, 30)).div(collateral), 30, 2, false)
                    : null;
                  return (
                    <tr
                      key={index}
                      className="table-content"
                      // onClick={() => handleSelectedPosition(completePosition)}
                    >
                      <td>
                        <div>
                          <img src={importImage("ic_" + symbol?.toLowerCase() + "_24.svg")} alt="tk" />
                          <div className="tk_info">
                            <div className="Exchange-list-title">
                            {symbol}/USD <span className="muted">#{position.posId}</span>
                            </div>
                            <div className="Exchange-list-info-label">
                              <span
                                className={cx({
                                  positive: position.isLong,
                                  negative: !position.isLong,
                                })}
                                style={{ color: position.isLong ? "#03f5ae" : "#F6475D" }}
                              >
                                {position.isLong ? `LONG` : `SHORT`}
                              </span>
                              {leverage && (
                                <span
                                  className=" leverage-box"
                                  style={{
                                    color: "#FFC700",
                                    backgroundColor: "rgba(255, 199, 0, 0.20)",
                                    height: 16,
                                    display: "inline-block",
                                    opacity: 1,
                                    borderRadius: "4px",
                                    padding: "0 4px",
                                    fontSize: "12px",
                                    lineHeight: "15.6px",
                                    fontWeight: 400,
                                    marginLeft: "4px",
                                  }}
                                >
                                  {leverage}X
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={`flag ${position.isLong ? "isLong" : "isShort"}`}></div>
                      </td>
                      <td>
                        {moment(new Date(position.createdAt * 1000)).format(dateFormat)}
                        <div className="muted fz-12">
                          {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
                        </div>
                      </td>
                      <td>
                        <div>{position?.positionType}</div>
                      </td>
                      <td>
                        <div>${formatAmount(size, 30, 2, true)}</div>
                        <div className="muted fz-12">
                          {formatAmount(posQty, 30, 4, true)} {symbol}
                        </div>
                      </td>
                      <td>
                        <div>${formatAmount(collateral, 30, 2, true)}</div>
                      </td>
                      <td>
                        <div>
                          {formatAmount(
                            position.positionType === "Limit Order" ? position?.lmtPrice : position?.stpPrice,
                            30,
                            currentMarket?.decimals || 2,
                            true
                          )}{" "}
                          <span className="muted">{position.positionType === "Limit Order" ? "LP" : "SP"}</span>
                        </div>
                        {position.positionType === "Stop Limit" && (
                          <div>
                            {formatAmount(position?.lmtPrice, 30, currentMarket?.decimals || 2, true)}{" "}
                            <span className="muted">LP</span>
                          </div>
                        )}
                      </td>

                      <td style={{ width: "30px" }}>
                        <div className="close-container">
                          <button
                            className="Exchange-list-action"
                            onClick={(e) => {
                              e.stopPropagation();
                              handlePendingTrigger(position);
                            }}
                            // disabled={position.size.eq(0)}
                          >
                            Cancel
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                } else {
                  const size = BigNumber.from(position.size);
                  const sizeDelta = BigNumber.from(position.sizeDelta);
                  const averagePrice = BigNumber.from(position.averagePrice);
                  const posQty = size.mul(parseValue(1, 30)).div(averagePrice);
                  const collateral = BigNumber.from(position.collateral);
                  const leverage = size.gt(0)
                    ? formatAmount(size.mul(parseValue(1, 30)).div(collateral), 30, 2, false)
                    : null;
                  const deltaQuantity = sizeDelta.mul(PRECISION).div(averagePrice);
                  const amountPercent = sizeDelta.mul(parseValue(100, 30)).div(size);
                  const completedPosition = {
                    ...position,
                    quantity: posQty,
                    averagePrice,
                    symbol,
                    size,
                    sizeDelta,
                    collateral,
                    leverage,
                    deltaQuantity,
                    amountPercent,
                  };
                  return (
                    <tr
                      key={index}
                      className="table-content"
                      // onClick={() => handleSelectedPosition(completePosition)}
                    >
                      <td>
                        {/* <div>
                          <img src={importImage("ic_" + symbol?.toLowerCase() + "_24.svg")} alt="tk" /> {symbol}/USD{" "}
                          <span className="muted">#{position.posId}</span>
                          <div className="Exchange-list-title">
                            <span className="muted">#{position.posId}</span>
                          </div>
                          <div className="tk_info">
                            <div className="Exchange-list-info-label">
                              <span
                                className={cx({
                                  positive: position.isLong,
                                  negative: !position.isLong,
                                })}
                                style={{ color: position.isLong ? "#03f5ae" : "#F6475D" }}
                              >
                                {position.isLong ? `LONG` : `SHORT`}
                              </span>
                              {leverage && (
                                <span
                                  className=" leverage-box"
                                  style={{
                                    color: "#FFC700",
                                    backgroundColor: "rgba(255, 199, 0, 0.20)",
                                    height: 16,
                                    display: "inline-block",
                                    opacity: 1,
                                    borderRadius: "4px",
                                    padding: "0 4px",
                                    fontSize: "12px",
                                    lineHeight: "15.6px",
                                    fontWeight: 400,
                                    marginLeft: "4px",
                                  }}
                                >
                                  {leverage}X
                                </span>
                              )}
                            </div>
                          </div>
                        </div> */}
                        <div>
                          <img src={importImage("ic_" + symbol?.toLowerCase() + "_24.svg")} alt="tk" />{" "}
                          <div className="tk_info">
                            <div className="Exchange-list-title">
                              {marketAsssets?.[position.tokenId]?.symbol}{" "}
                              <span className="muted">#{position.posId}</span>
                            </div>
                            <div className="Exchange-list-info-label">
                              <span
                                className={cx({ positive: position.isLong, negative: !position.isLong })}
                                style={{ color: position.isLong ? "#03f5ae" : "#F6475D" }}
                              >
                                {position.isLong ? `LONG` : `SHORT`}
                              </span>
                              {leverage && (
                                <span
                                  className=" leverage-box"
                                  style={{
                                    color: "#FFC700",
                                    backgroundColor: "rgba(255, 199, 0, 0.20)",
                                    height: 16,
                                    display: "inline-block",
                                    opacity: 1,
                                    borderRadius: "4px",
                                    padding: "0 4px",
                                    fontSize: "12px",
                                    lineHeight: "15.6px",
                                    fontWeight: 400,
                                    marginLeft: "4px",
                                  }}
                                >
                                  {leverage}X
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={`flag ${position.isLong ? "isLong" : "isShort"}`}></div>
                      </td>
                      <td>
                        {moment(new Date(position.createdAt * 1000)).format(dateFormat)}
                        <div className="muted fz-12">
                          {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
                        </div>
                      </td>
                      <td>
                        <div>Trailing Stop</div>
                      </td>
                      <td>
                        <div>${formatAmount(size, 30, 2, true)}</div>
                        <div className="muted fz-12">
                          {formatAmount(posQty, 30, 4, true)} {symbol}
                        </div>
                      </td>
                      <td>
                        <div>${formatAmount(collateral, 30, 2, true)}</div>
                      </td>
                      <td>
                        <div>
                          {position.stepType == 0
                            ? formatAmount(BigNumber.from(position.stepAmount), 30, currentMarket?.decimals || 2, true)
                            : formatAmount(
                                BigNumber.from(position.stepAmount),
                                3,
                                currentMarket?.decimals || 2,
                                true
                              )}{" "}
                          <span className="muted">{position.stepType == 1 ? "T%" : "TA"}</span>
                        </div>
                        <div>
                          {" "}
                          {sizeDelta.gte(size) ? 100 : formatAmount(amountPercent, 30, 2, true)}{" "}
                          <span className="muted">%</span>
                        </div>
                      </td>
                      <td style={{ width: "30px" }}>
                        <div className="close-container">
                          <button
                            className="Exchange-list-action"
                            onClick={(e) => {
                              e.stopPropagation();
                              handlePendingTrigger(position);
                            }}
                            // disabled={position.size.eq(0)}
                          >
                            Cancel
                          </button>
                          <button
                            className="PositionDropdown-dots-icon"
                            onClick={() => {
                              setSelectedPosition(completedPosition);
                              setIsEditTralingStopModalOpen(true);
                            }}
                          >
                            <img
                              src={editIcon}
                              alt="icon"
                              style={{ width: "16px", height: "16px", maxWidth: "16px" }}
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                }
              })}
          </tbody>
        </table>
      )}
      {myOrders && myOrders.length > PAGE_SIZE && (
        <PaginationV2 rowsPerPage={PAGE_SIZE} rows={myOrders.length} currentPage={page} setCurrentPage={setPage} />
      )}
    </div>
  );
}
