import React, { useEffect, useMemo, useState } from "react";
import { t, Trans } from "@lingui/macro";
import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import "./SwapBox.css";
import {
  LEVERAGE_ORDER_OPTIONS_V2,
  STOP_MARKET,
  SWAP_OPTIONS_V2,
  convertPositionFromLogsData,
  getErrorTextStopLoss,
  getErrorTextTakeProfit,
  getSpread,
} from "lib/legacy";
import cx from "classnames";
import useSWR from "swr";
import { BigNumber, ethers } from "ethers";
import { getTokenSymbolFromString } from "domain/tokens";

import {
  BASIS_POINTS_DIVISOR,
  calculatePositionDelta,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  getLiqPrice,
  getAccruedFees,
  getPositionKey,
  LEVERAGE_ORDER_OPTIONS,
  LIMIT,
  LONG,
  MARKET,
  SHORT,
  STOP,
  SWAP,
  SWAP_OPTIONS,
  SWAP_ORDER_OPTIONS,
  USD_DECIMALS,
  USDG_ADDRESS,
  MAX_ALLOWED_LEVERAGE,
  LEVERAGE_MARKS,
  STOP_LIMIT,
  ORDER_TYPES,
} from "lib/legacy";
import {
  ARBITRUM,
  AVALANCHE,
  FANTOM,
  getChainName,
  getConstant,
  IS_NETWORK_DISABLED,
  isSupportedChain,
} from "config/chains";
import * as Api from "domain/legacy";
import { getContract } from "config/contracts";

import Tab from "../Tab/Tab";
import ExchangeInfoRow from "./ExchangeInfoRow";
import ConfirmationBox from "./ConfirmationBox";
import OrdersToa from "./OrdersToa";
import SettingsManager from "abis/SettingsManager.json";

import VaultMsp from "abis/VaultMsp.json";
import OrderVaultMsp from "abis/OrderVaultMsp.json";
import WETH from "abis/WETH.json";

import longImg from "img/long.svg";
import longLightImg from "img/trade/long-light.svg";
import shortLightImg from "img/trade/short-light.svg";
import shortImg from "img/short.svg";

import swapImg from "img/swap.svg";

import { useAffiliateCodes, useUserReferralCode, useUserReferralCodeV2 } from "domain/referrals";
import { callContract, contractFetcher } from "lib/contracts";
import { replaceNativeTokenAddress } from "domain/tokens";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { usePrevious } from "lib/usePrevious";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  getDisplayDecimalByAmount,
  parseValue,
} from "lib/numbers";
import { TOKEN_SHOWS_DECIMALS, getToken, getTokenBySymbol, getTokens, getWhitelistedTokens } from "config/tokens";
import PercentageButtons from "./PercentageButtons";
import TakeProfitCard from "./TakeProfitCard";
import StopLossCard from "./StopLossCard";
import { preventSpecialCharacters } from "utils/helpers";
import { THEME_KEY } from "config/localStorage";
import Tooltip from "components/Tooltip/Tooltip";
import { switchNetwork } from "lib/wallets";
import { useThemeContext } from "contexts/ThemeProvider";
const abiDecoder = require("abi-decoder");
window.Buffer = window.Buffer || require("buffer").Buffer;

const { AddressZero } = ethers.constants;

const leverageSliderHandle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${parseFloat(value).toFixed(1)}x`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Slider.Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};
var timeOutOpenPosition = null;
export default function SwapBox(props) {
  const {
    active,
    library,
    account,
    swapOption,
    setSwapOption,
    setPendingTxns,
    tokenSelection,
    setIsConfirming,
    isConfirming,
    isPendingConfirmation,
    setIsPendingConfirmation,
    flagOrdersEnabled,
    chainId,
    savedSlippageAmount,
    userTokenBalances,
    marketTokenInfo,
    defaultChartToken,
    setSavedSlippageAmount,
    justOpenPositions,
  } = props;
  const theme = useThemeContext();
  const SWAP_ICONS = {
    [LONG]: theme.isLightTheme ? longLightImg : longImg,
    [SHORT]: theme.isLightTheme ? shortLightImg : shortImg,
    [SWAP]: swapImg,
  };

  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);
  const { userReferralCode, userReferralInviter } = useUserReferralCodeV2(chainId, account);
  const settingsManagerAddress = getContract(chainId, "SettingsManager");

  const chartSymbol = getTokenSymbolFromString(defaultChartToken) || "";
  const [isTakeProfit, setIsTakeProfit] = useState(false);
  const [isStopLoss, setIsStopLoss] = useState(false);

  const [takeProfitTriggerPrice, setTakeProfitTriggerPrice] = useState("0");
  const [stopLossTriggerPrice, setStopLossTriggerPrice] = useState("0");

  const [takeProfitCloseQuantity, setTakeProfitCloseQuantity] = useState(100);
  const [stopLossCloseQuantity, setStopLossCloseQuantity] = useState(100);

  const [isShowStopLoss, setIsShowStopLoss] = useState(false);
  const [isShowTakeProfit, setIsShowTakeProfit] = useState(false);

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }
  const currentMarket = marketTokenInfo?.find((x) => x.symbol === defaultChartToken);

  const maxLeverage = currentMarket?.maxLeverage ? Number(currentMarket?.maxLeverage) / BASIS_POINTS_DIVISOR : 100;
  const { localStorageCodeOwner: code } = useUserReferralCode(library, chainId, account);
  // console.log("???", code);
  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;
  const isSwap = swapOption === SWAP;
  const assetId = currentMarket?.id;
  const { data: tradingFee } = useSWR(
    assetId && [`Exchange:tradingFee:${[active, assetId, isLong]}`, chainId, settingsManagerAddress, "tradingFee"],
    {
      fetcher: contractFetcher(library, SettingsManager, [assetId, isLong]),
    }
  );

  const [leverageOption, setLeverageOption] = useLocalStorageSerializeKey(
    [chainId, "Trade-v2-Exchange-swap-leverage-option"],
    "2"
  );
  const [isLeverageSliderEnabled, setIsLeverageSliderEnabled] = useLocalStorageSerializeKey(
    [chainId, "trade-v2-Exchange-swap-leverage-slider-enabled"],
    true
  );

  let [orderOption, setOrderOption] = useLocalStorageSerializeKey([chainId, "Trade-v2-Order-option"], MARKET);
  // if (!flagOrdersEnabled) {
  //   orderOption = MARKET;
  // }

  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };
  const onChangeLeverage = (value) => {
    if (value && value.includes(".")) {
      var arr = value.split(".");
      if (arr[1].length <= 1) setLeverageOption(value);
    } else {
      setLeverageOption(value);
    }
  };
  const isMarketOrder = orderOption === MARKET;
  const isLimitOrder = orderOption === LIMIT;
  const isStopLimitOrder = orderOption === STOP_LIMIT;
  const orderOptions = LEVERAGE_ORDER_OPTIONS_V2;
  const orderOptionLabels = {
    [STOP_MARKET]: `Stop Market`,
    [MARKET]: `Market`,
    [LIMIT]: `Limit`,
    [STOP_LIMIT]: "Stop Limit",
  };

  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const [triggerStopPriceValue, setTriggerStopPriceValue] = useState("");
  const triggerPriceUsd = isMarketOrder ? 0 : parseValue(triggerPriceValue, USD_DECIMALS);

  const onTriggerPriceChange = (evt) => {
    setTriggerPriceValue(evt.target.value || "");
  };
  const onTriggerStopPriceChange = (evt) => {
    setTriggerStopPriceValue(evt.target.value || "");
  };

  const onTriggerRatioChange = (evt) => {
    setTriggerRatioValue(evt.target.value || "");
  };

  const { data: minCollateral } = useSWR(["ExChange:minCollateral", chainId, settingsManagerAddress, "minCollateral"], {
    fetcher: contractFetcher(library, SettingsManager),
  });

  const { data: marketOrderGasFee } = useSWR(
    ["ExChange:marketOrderGasFee", chainId, settingsManagerAddress, "marketOrderGasFee"],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const { data: triggerGasFee } = useSWR(["ExChange:triggerGasFee", chainId, settingsManagerAddress, "triggerGasFee"], {
    fetcher: contractFetcher(library, SettingsManager),
  });

  const { data: deductFeePercent } = useSWR(
    chainId && account && ["ExChange:deductFeePercent", chainId, settingsManagerAddress, "deductFeePercent", account],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );

  const { data: liquidateThreshold } = useSWR(
    currentMarket?.id && [
      "ExChange:liquidateThreshold",
      chainId,
      settingsManagerAddress,
      "liquidateThreshold",
      currentMarket?.id,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );

  const getErrorCollateral = () => {
    if (!Number(fromValue)) {
      return false;
    }

    if (minCollateral && parseValue(fromValue, 30).lt(minCollateral)) {
      return `A minimum of $${formatAmount(minCollateral, 30, 2)} is required to place a trade`;
    }
    if (posSize && posFees) {
      const remain = userTokenBalances?.nusdBalance?.sub(parseValue(fromValue || 0, 30));
      if (remain && remain.lt(posFees.mul(parseValue(1, 20)))) {
        return `An additional amount of $${formatAmount(
          parseValue(fromValue || 0, 30)
            .add(posFees.mul(parseValue(1, 20)))
            .sub(userTokenBalances?.nusdBalance),
          30,
          2,
          true
        )} is required in your exchange balance to place the trade`;
      }
    }
    return false;
  };

  const [triggerRatioValue, setTriggerRatioValue] = useState("");

  let entryMarkPrice = isLong
    ? BigNumber.from(currentMarket?.askPrice || 0)
    : BigNumber.from(currentMarket?.bidPrice || 0);
  let posSize;
  if (fromValue && leverageOption)
    posSize = parseValue(fromValue, 5)
      ?.mul(bigNumberify(parseInt(leverageOption * BASIS_POINTS_DIVISOR)))
      .div(BASIS_POINTS_DIVISOR);

  let posQty;
  if (entryMarkPrice && entryMarkPrice.gt(0) && posSize && posSize.gt(0)) {
    posQty = posSize
      ?.mul(expandDecimals(1, USD_DECIMALS))
      .div(
        orderOption === MARKET
          ? entryMarkPrice
          : orderOption === STOP_LIMIT
          ? triggerStopPriceValue
            ? parseValue(triggerStopPriceValue, 30).gt(0)
              ? parseValue(triggerStopPriceValue, 30)
              : entryMarkPrice
            : entryMarkPrice
          : triggerPriceValue
          ? parseValue(triggerPriceValue, 30).gt(0)
            ? parseValue(triggerPriceValue, 30)
            : entryMarkPrice
          : entryMarkPrice
      );
  }

  let posFees;
  if (tradingFee && posSize) {
    posFees = tradingFee?.mul(posSize) || 0;
  }
  let feeDiscount;
  if (posFees && deductFeePercent) {
    feeDiscount = deductFeePercent.mul(posFees).mul(1000);
  }

  let spread = getSpread(
    BigNumber.from(currentMarket?.askPrice || 0),
    BigNumber.from(currentMarket?.bidPrice || 0),
    isLong
  );

  let liqThreshold, collateral, accruedFees, entryPrice;
  liqThreshold = liquidateThreshold;
  collateral = parseValue(fromValue, 5);
  entryPrice = entryMarkPrice;
  let liquidPrice = 0;
  accruedFees = getAccruedFees(posFees, bigNumberify(0), bigNumberify(0), bigNumberify(0));

  if (liqThreshold && collateral && posSize && accruedFees && entryPrice && collateral.gt(0)) {
    const liqPrice = getLiqPrice(
      liqThreshold,
      collateral,
      posSize,
      accruedFees,
      isLong,
      orderOption === MARKET
        ? entryMarkPrice
        : orderOption === STOP_LIMIT
        ? triggerStopPriceValue
          ? parseValue(triggerStopPriceValue, 30)
          : entryMarkPrice
        : triggerPriceValue
        ? parseValue(triggerPriceValue, 30)
        : entryMarkPrice
    );
    liquidPrice = liqPrice;
  }

  const getFromValueError = () => {
    if (!fromValue || !Number(fromValue)) {
      return ["Enter an amount"];
    }

    if (parseValue(fromValue || 0, 30).gt(userTokenBalances?.nusdBalance || bigNumberify(0))) {
      return ["Amount exceeds balance"];
    }

    return [false];
  };

  const stopLossTriggerError = useMemo(() => {
    return getErrorTextStopLoss({
      isLong,
      closeQuantity: parseValue(stopLossCloseQuantity, 30),
      liqPrice: liquidPrice,
      markPrice:
        orderOption === MARKET
          ? entryMarkPrice
          : orderOption === STOP_LIMIT
          ? triggerStopPriceValue
            ? parseValue(triggerStopPriceValue, 30).gt(0)
              ? parseValue(triggerStopPriceValue, 30)
              : entryMarkPrice
            : entryMarkPrice
          : triggerPriceValue
          ? parseValue(triggerPriceValue, 30).gt(0)
            ? parseValue(triggerPriceValue, 30)
            : entryMarkPrice
          : entryMarkPrice,
      triggerPrice: parseValue(stopLossTriggerPrice, 30),
      isShow: isShowStopLoss,
    });
  }, [
    entryMarkPrice,
    isLong,
    isShowStopLoss,
    liquidPrice,
    orderOption,
    stopLossCloseQuantity,
    stopLossTriggerPrice,
    triggerPriceValue,
    triggerStopPriceValue,
  ]);

  const takeProfitTriggerError = useMemo(() => {
    return getErrorTextTakeProfit({
      isLong,
      closeQuantity: parseValue(takeProfitCloseQuantity, 30),
      liqPrice: liquidPrice,
      markPrice:
        orderOption === MARKET
          ? entryMarkPrice
          : orderOption === STOP_LIMIT
          ? triggerStopPriceValue
            ? parseValue(triggerStopPriceValue, 30).gt(0)
              ? parseValue(triggerStopPriceValue, 30)
              : entryMarkPrice
            : entryMarkPrice
          : triggerPriceValue
          ? parseValue(triggerPriceValue, 30).gt(0)
            ? parseValue(triggerPriceValue, 30)
            : entryMarkPrice
          : entryMarkPrice,
      triggerPrice: parseValue(takeProfitTriggerPrice, 30),
      isShow: isShowTakeProfit,
    });
  }, [
    entryMarkPrice,
    isLong,
    isShowTakeProfit,
    liquidPrice,
    orderOption,
    takeProfitCloseQuantity,
    takeProfitTriggerPrice,
    triggerPriceValue,
    triggerStopPriceValue,
  ]);

  const getError = () => {
    switch (orderOption) {
      case LIMIT:
        if (!triggerPriceValue || !Number(triggerPriceValue)) {
          return ["Enter limit price"];
        }
        break;
      case STOP_MARKET:
        if (!triggerPriceValue || !Number(triggerPriceValue)) {
          return ["Enter stop price"];
        }
        break;
      case STOP_LIMIT:
        if (!triggerPriceValue || !Number(triggerPriceValue)) {
          return ["Enter stop price"];
        }
        if (!triggerStopPriceValue || !Number(triggerStopPriceValue)) {
          return ["Enter limit price"];
        }
        break;
      default:
        break;
    }

    if (isShowStopLoss) {
      if (stopLossCloseQuantity <= 0) {
        return ["Enter close quantity for stop loss"];
      }
      if (!stopLossTriggerPrice || Number(stopLossTriggerPrice) <= 0) {
        return ["Enter trigger price for stop loss"];
      }
    }

    if (isShowTakeProfit) {
      if (takeProfitCloseQuantity <= 0) {
        return ["Enter close quantity for take profit"];
      }
      if (!takeProfitTriggerPrice || Number(takeProfitTriggerPrice) <= 0) {
        return ["Enter trigger price for take profit"];
      }
    }

    return getFromValueError();
  };

  const getMarketError = () => {
    if (orderOption === MARKET) {
      if (fromValue && Number(fromValue) <= 0) return true;
    }
    if (orderOption !== MARKET) {
      if (triggerPriceValue && Number(triggerPriceValue) <= 0) return true;
    }
    if (orderOption === STOP_LIMIT) {
      if (triggerStopPriceValue && Number(triggerStopPriceValue) <= 0) return true;
    }

    if (isLong) {
      if (
        posSize
          .mul(parseValue(1, 25))
          .gt(
            BigNumber.from(currentMarket?.maxOpenInterestPerAssetPerSides?.[0]?.value || 0).sub(
              parseValue(currentMarket?.openInterest?.long || 0, 30)
            )
          )
      )
        return true;
    } else if (
      posSize
        .mul(parseValue(1, 25))
        .gt(
          BigNumber.from(currentMarket?.maxOpenInterestPerAssetPerSides?.[1]?.value || 0).sub(
            parseValue(currentMarket?.openInterest?.short || 0, 30)
          )
        )
    )
      return true;
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!active) {
      return true;
    }
    const [error, modal] = getError();
    if (error) {
      return false;
    }
    const errorMarket = getMarketError();
    if (errorMarket) return false;

    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (!active) {
      return `Connect Wallet`;
    }
    if (!isSupportedChain(chainId)) {
      return `Incorrect Network`;
    }

    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }

    if (!isMarketOrder) return isLong ? "Long" : "Short";

    if (isSwap) {
      return `Swap`;
    }
    if (isLong) {
      if (
        posSize
          .mul(parseValue(1, 25))
          .gt(
            BigNumber.from(currentMarket?.maxOpenInterestPerAssetPerSides?.[0]?.value || 0).sub(
              parseValue(currentMarket?.openInterest?.long || 0, 30)
            )
          )
      )
        return "Max long OI exceeded ";
    } else if (
      posSize
        .mul(parseValue(1, 25))
        .gt(
          BigNumber.from(currentMarket?.maxOpenInterestPerAssetPerSides?.[1]?.value || 0).sub(
            parseValue(currentMarket?.openInterest?.short || 0, 30)
          )
        )
    )
      return "Max short OI exceeded";
    if (isLong) {
      return `Long`;
    }

    return `Short`;
  };

  const onFromValueChange = (e) => {
    setFromValue(e.target.value);
  };

  let referralCode = ethers.constants.HashZero;
  if (userReferralCode) {
    referralCode = userReferralCode;
  }

  const increasePosition = async () => {
    setIsSubmitting(true);
    let tokenId = currentMarket?.id;

    // 0 -> market order
    // 1 -> limit order
    // 2 -> stop-market order
    // 3 -> stop-limit order
    let orderType = ORDER_TYPES[orderOption];

    // for market order:  _params[0] -> allowed price (revert if exceeded)
    // for limit order: _params[0] -> limit price
    // In stop-market order: _params[1] -> stop price,
    // In stop-limit order: _params[0] -> limit price, _params[1] -> stop price
    // for all orders: _params[2] -> collateral
    // for all orders: _params[3] -> size
    // let allowedPrice = "892155750000000000000000000000";
    const slippageStorage = window.localStorage.getItem(`[${chainId},"Exchange-swap-slippage-basis-points-v3"]`);
    const marketAllowPrice = isLong
      ? entryMarkPrice.add(entryMarkPrice.mul(parseValue(slippageStorage / 10000, 30)).div(parseValue(1, 30)))
      : entryMarkPrice.sub(entryMarkPrice.mul(parseValue(slippageStorage / 10000, 30)).div(parseValue(1, 30)));
    const entryPriceCurrent = BigNumber.from(0).add(entryMarkPrice);
    let allowedPrice =
      orderType === ORDER_TYPES["Market"]
        ? marketAllowPrice
        : orderType === ORDER_TYPES["Stop Limit"]
        ? parseValue(triggerStopPriceValue, USD_DECIMALS)
        : orderType === ORDER_TYPES["Stop Market"]
        ? 0
        : parseValue(triggerPriceValue, USD_DECIMALS);
    let slippage =
      orderType === ORDER_TYPES["Stop Market"] || orderType === ORDER_TYPES["Stop Limit"]
        ? parseValue(triggerPriceValue, USD_DECIMALS)
        : orderType === ORDER_TYPES["Market"]
        ? slippageStorage * 10
        : 0; //sllipage
    let collateral = parseValue(fromValue, 30);
    let size = posSize.mul(parseValue(1, 25));

    const longOrShortText = isLong ? `Long` : `Short`;
    const successMsg = `Requested ${isLong ? "long" : "short"} ${chartSymbol}`;
    if (!isTakeProfit && !isStopLoss) {
      Api.newPositionOrder(
        chainId,
        library,
        tokenId,
        isLong,
        orderType,
        allowedPrice,
        slippage,
        collateral,
        size,
        code || AddressZero,
        {
          value: marketOrderGasFee,
          setPendingTxns,
          sentMsg: `${longOrShortText} submitted.`,
          failMsg: `${longOrShortText} failed.`,
          successMsg,
          // for Arbitrum, sometimes the successMsg shows after the position has already been executed
          // hide the success message for Arbitrum as a workaround
          hideSuccessMsg: chainId === ARBITRUM,
        }
      )
        .then(async (e) => {
          const receipt = await e.wait();
          try {
            abiDecoder.addABI(OrderVaultMsp.abi);
            const decodedLogs = abiDecoder.decodeLogs(receipt.logs);
            const eventLog = decodedLogs.filter((item) => item.name === "NewOrder");
            if (eventLog && eventLog.length > 0) {
              const events = eventLog[0]?.events;
              const position = convertPositionFromLogsData(
                events,
                formatAmount(entryPriceCurrent, 30, 30, false),
                Date.now() / 1000
              );
              if (orderType === ORDER_TYPES["Market"]) {
                justOpenPositions.push(position);
                if (timeOutOpenPosition) clearTimeout(timeOutOpenPosition);
                timeOutOpenPosition = setTimeout(() => {
                  justOpenPositions.length = 0;
                }, 30000);
              }
            }
            setIsConfirming(false);
            setFromValue("");
            setTriggerPriceValue("");
            setTriggerStopPriceValue("");
          } catch (e) {
            console.log("error", e);
          }
        })
        .finally(() => {
          // setIsConfirming(false);

          setIsSubmitting(false);
          setIsPendingConfirmation(false);
        });
    } else {
      const isTPS = [isTakeProfit];
      if (isTakeProfit && isStopLoss) isTPS.push(false);
      const prices = [];
      if (isTakeProfit && !isStopLoss) {
        prices.push(parseValue(takeProfitTriggerPrice, USD_DECIMALS));
      } else if (!isTakeProfit && isStopLoss) {
        prices.push(parseValue(stopLossTriggerPrice, USD_DECIMALS));
      } else {
        prices.push(parseValue(takeProfitTriggerPrice, USD_DECIMALS));
        prices.push(parseValue(stopLossTriggerPrice, USD_DECIMALS));
      }
      const amountPercents = [];
      if (isTakeProfit && !isStopLoss) {
        amountPercents.push(takeProfitCloseQuantity * 1000);
      } else if (!isTakeProfit && isStopLoss) {
        amountPercents.push(stopLossCloseQuantity * 1000);
      } else {
        amountPercents.push(takeProfitCloseQuantity * 1000);
        amountPercents.push(stopLossCloseQuantity * 1000);
      }
      Api.newPositionOrderWithTPSL(
        chainId,
        library,
        tokenId,
        isLong,
        orderType,
        allowedPrice,
        slippage,
        collateral,
        size,
        code || AddressZero,
        isTPS,
        prices,
        amountPercents,
        {
          value: marketOrderGasFee.add(BigNumber.from(isTPS.length).mul(triggerGasFee)),
          setPendingTxns,
          sentMsg: `${longOrShortText} submitted.`,
          failMsg: `${longOrShortText} failed.`,
          successMsg,
          // for Arbitrum, sometimes the successMsg shows after the position has already been executed
          // hide the success message for Arbitrum as a workaround
          hideSuccessMsg: chainId === ARBITRUM,
        }
      )
        .then(async (e) => {
          const receipt = await e.wait();
          try {
            abiDecoder.addABI(OrderVaultMsp.abi);
            const decodedLogs = abiDecoder.decodeLogs(receipt.logs);
            const eventLog = decodedLogs.filter((item) => item.name === "NewOrder");
            if (eventLog && eventLog.length > 0) {
              const events = eventLog[0]?.events;
              const position = convertPositionFromLogsData(
                events,
                formatAmount(entryPriceCurrent, 30, 30, false),
                Date.now() / 1000
              );
              if (orderType === ORDER_TYPES["Market"])
                // setPositionNew(position)
                justOpenPositions.push(position);
            }
            setIsConfirming(false);
          } catch (e) {
            console.log("????,", e);
          }
        })
        .finally(() => {
          // setIsConfirming(false);
          setIsSubmitting(false);
          setIsPendingConfirmation(false);
        });
    }
  };
  const onSwapOptionChange = (opt) => {
    setSwapOption(opt);
    if (orderOption === STOP) {
      setOrderOption(MARKET);
    }
    setFromValue("");
    setToValue("");
    setTriggerPriceValue("");
    setTriggerRatioValue("");
  };

  const onConfirmationClick = () => {
    if (!active) {
      props.connectWallet();
      return;
    }
    setIsPendingConfirmation(true);
    increasePosition();
  };

  const onClickPrimary = () => {
    if (!active) {
      props.connectWallet();
      return;
    }

    setIsConfirming(true);
    setIsHigherSlippageAllowed(false);
  };

  const isStopOrder = orderOption === STOP_MARKET;
  const showFromAndToSection = true;
  const showTriggerPriceSection = !isSwap && !isMarketOrder;
  const showTriggerRatioSection = isSwap && !isMarketOrder && !isStopOrder;

  const handleSelectPercentage = (percentage) => {
    if (!userTokenBalances?.nusdBalance || userTokenBalances?.nusdBalance.eq(0)) {
      return;
    }
    const temptValue = userTokenBalances?.nusdBalance.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
    const tempFee = temptValue.mul(parseValue(leverageOption, 30)).mul(8).div(parseValue(10000, 30));
    if (temptValue.add(tempFee).lt(userTokenBalances?.nusdBalance)) {
      setFromValue(formatAmount(temptValue, 30, 6, false));
    } else {
      const maxColateral = userTokenBalances?.nusdBalance
        .mul(parseValue(1, 30))
        .div(parseValue(1 + (leverageOption * 8) / 10000, 30));
      setFromValue(formatAmount(maxColateral, 30, 6, false));
    }

    // setFromValue(formatAmount(userTokenBalances?.nusdBalance.sub(tempFee), 30, 6, false));
  };
  const onSliderChange = (sliderValue) => {
    if (fromValue && fromValue !== 0) {
      const temptValue = parseValue(fromValue, 30);
      const tempFee = temptValue.mul(parseValue(sliderValue, 30)).mul(8).div(parseValue(10000, 30));
      if (temptValue.add(tempFee).gt(userTokenBalances?.nusdBalance)) {
        const maxColateral = userTokenBalances?.nusdBalance
          .mul(parseValue(1, 30))
          .div(parseValue(1 + (sliderValue * 8) / 10000, 30));
        setFromValue(formatAmount(maxColateral, 30, 6, false));
      }
    }
    setLeverageOption(sliderValue);
  };
  const SWAP_LABELS = {
    [LONG]: `LONG`,
    [SHORT]: `SHORT`,
    [SWAP]: `SWAP`,
  };

  const handleBlurLeverage = ({ target }) => {
    const val = target.value;
    if (!val || val < 1.1 || val > maxLeverage) {
      setLeverageOption(1.1);
      return;
    }
    setLeverageOption(target.value);
  };

  return (
    <div className={`Exchange-swap-box ${swapOption === LONG ? "box-trade-long" : "box-trade-short"}`}>
      <div className="swap-box-v2">
        <Tab
          icons={SWAP_ICONS}
          options={SWAP_OPTIONS_V2}
          optionLabels={SWAP_LABELS}
          option={swapOption}
          onChange={onSwapOptionChange}
          className="Exchange-swap-option-tabs"
          isBoldFontWeight={true}
        />
      </div>
      <div
        className={`Exchange-swap-box-inner App-box-highlight box-trade-v2
        `}
      >
        <div>
          {
            <Tab
              options={orderOptions}
              optionLabels={orderOptionLabels}
              className="Exchange-swap-order-type-tabs"
              type="inline"
              option={orderOption}
              onChange={onOrderOptionChange}
            />
          }
        </div>
        {showFromAndToSection && (
          <React.Fragment>
            <div className="Exchange-swap-section exchange-section-first">
              <div className="Exchange-swap-section-top">
                <div className="">
                  <Tooltip
                    handle={`Collateral`}
                    position="left-top"
                    renderContent={() => {
                      return (
                        <span>
                          When you click on the 100% option, your max collateral amount will be estimated based on your
                          free collateral and Open pos. fees
                        </span>
                      );
                    }}
                  />
                </div>
                <div className="muted align-right clickable">
                  <span>
                    Free Collateral: $
                    {userTokenBalances?.nusdBalance
                      ? formatAmount(userTokenBalances?.nusdBalance, USD_DECIMALS, 2, true)
                      : "0.00"}
                  </span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    autoFocus
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={fromValue}
                    onChange={onFromValueChange}
                    onKeyDown={preventSpecialCharacters}
                  />
                  <div className="usd-txt">USD</div>
                </div>
              </div>
            </div>
            {<span className="validate-error">{getErrorCollateral()}</span>}
            <PercentageButtons
              onChangePercentage={handleSelectPercentage}
              balance={Number(formatAmount(userTokenBalances?.nusdBalance || 0, USD_DECIMALS, 6, false)) || 0}
              value={Number(fromValue || 0)}
              decimals={6}
              fullBalance={userTokenBalances?.nusdBalance}
              isUsd={true}
            />

            {showTriggerRatioSection && (
              <div className="Exchange-swap-section" style={{ marginTop: "12px" }}>
                <div className="Exchange-swap-section-top">
                  <div className="muted">
                    <span>Price</span>
                  </div>
                </div>
                <div className="Exchange-swap-section-bottom">
                  <div className="Exchange-swap-input-container">
                    <input
                      type="number"
                      min="0"
                      placeholder="0.0"
                      className="Exchange-swap-input small"
                      value={triggerRatioValue}
                      onChange={onTriggerRatioChange}
                      onKeyDown={preventSpecialCharacters}
                    />
                  </div>
                </div>
              </div>
            )}
            {showTriggerPriceSection && (
              <div className="Exchange-swap-section" style={{ marginTop: "12px" }}>
                <div className="Exchange-swap-section-top">
                  <div className="muted">
                    <span>{orderOption === LIMIT ? "Limit Price" : "Stop Price"}</span>
                  </div>
                  <div
                    className="muted align-right clickable"
                    onClick={() => {
                      setTriggerPriceValue(
                        formatAmountFree(entryMarkPrice, USD_DECIMALS, TOKEN_SHOWS_DECIMALS[defaultChartToken])
                      );
                    }}
                  >
                    <span>
                      Mark: ${formatAmount(entryMarkPrice, USD_DECIMALS, TOKEN_SHOWS_DECIMALS[defaultChartToken], true)}
                    </span>
                  </div>
                </div>
                <div className="Exchange-swap-section-bottom">
                  <div className="Exchange-swap-input-container">
                    <input
                      type="number"
                      min="0"
                      placeholder="0.0"
                      className="Exchange-swap-input"
                      value={triggerPriceValue}
                      onChange={onTriggerPriceChange}
                      onKeyDown={preventSpecialCharacters}
                    />
                  </div>
                  <div className="PositionEditor-token-symbol">USD</div>
                </div>
              </div>
            )}
            {orderOption === STOP_LIMIT && (
              <div className="Exchange-swap-section" style={{ marginTop: "12px" }}>
                <div className="Exchange-swap-section-top">
                  <div className="muted">
                    <span>{"Limit Price"}</span>
                  </div>
                  <div
                    className="muted align-right clickable"
                    onClick={() => {
                      setTriggerStopPriceValue(
                        formatAmountFree(entryMarkPrice, USD_DECIMALS, TOKEN_SHOWS_DECIMALS[defaultChartToken])
                      );
                    }}
                  >
                    <span>
                      Mark: ${formatAmount(entryMarkPrice, USD_DECIMALS, TOKEN_SHOWS_DECIMALS[defaultChartToken], true)}
                    </span>
                  </div>
                </div>
                <div className="Exchange-swap-section-bottom">
                  <div className="Exchange-swap-input-container">
                    <input
                      type="number"
                      min="0"
                      placeholder="0.0"
                      className="Exchange-swap-input"
                      value={triggerStopPriceValue}
                      onChange={onTriggerStopPriceChange}
                      onKeyDown={preventSpecialCharacters}
                    />
                  </div>
                  <div className="PositionEditor-token-symbol">USD</div>
                </div>
              </div>
            )}
            <div className="exchange-info-container">
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Pos. Size</Trans>
                </div>
                <div className="align-right bold">${posSize ? formatAmount(posSize, 5, 3, true) : "0"}</div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Pos. Qty</Trans>
                </div>
                <div className="align-right bold">
                  {posQty ? formatAmount(posQty, 5, 3, true) : "0"} {chartSymbol}
                </div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Current Price</Trans>
                </div>
                <div className="align-right bold">
                  ${formatAmount(entryMarkPrice, USD_DECIMALS, currentMarket?.decimals, true)}
                </div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Liq. Price</Trans>
                </div>
                <div className="align-right bold">
                  ${formatAmount(liquidPrice, USD_DECIMALS, currentMarket?.decimals, true)}
                </div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <div>Open Pos. Fees ({tradingFee ? formatAmount(tradingFee, 3) : "0"}%)</div>
                </div>
                <div className="align-right bold">${posFees ? formatAmount(posFees, 10, 3, true) : "0"}</div>
              </div>
              {/* <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <div>Fee Discount (--%)</div>
                </div>

                <div className="align-right bold">~$--</div>
              </div> */}
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Spread</Trans>
                </div>
                <div className="align-right bold">
                  {spread?.value ? formatAmount(spread?.value, 30, 3, true) : "--"}%
                </div>
              </div>
            </div>
            <div className="divider" />
          </React.Fragment>
        )}

        <div className="Exchange-leverage-box">
          <div className="bold">Leverage slider</div>
          {isLeverageSliderEnabled && (
            <div className="Exchange-leverage-slider-container">
              <div className="percentahe-value">
                {/* <span>{leverageOption}</span> */}
                <input
                  className="percentahe-input"
                  value={leverageOption}
                  type="number"
                  onChange={({ target }) => onChangeLeverage(target.value)}
                  onBlur={handleBlurLeverage}
                  onKeyDown={preventSpecialCharacters}
                />
                <span>x</span>
              </div>
              <div
                className={cx("Exchange-leverage-slider", "App-slider", {
                  // positive: isLong,
                  // negative: isShort,
                  normalSlider: true,
                })}
              >
                <Slider
                  min={1.0}
                  max={maxLeverage}
                  step={0.5}
                  marks={LEVERAGE_MARKS(chainId, maxLeverage)}
                  handle={leverageSliderHandle}
                  onChange={(value) => onSliderChange(value)}
                  defaultValue={leverageOption}
                  value={leverageOption}
                />
              </div>
            </div>
          )}
          <div className="divider" />
          <TakeProfitCard
            isLong={isLong}
            marketSymbol={chartSymbol}
            marketPrice={
              orderOption === MARKET
                ? entryMarkPrice
                : orderOption === STOP_LIMIT
                ? triggerStopPriceValue
                  ? parseValue(triggerStopPriceValue, 30)
                  : entryMarkPrice
                : triggerPriceValue
                ? parseValue(triggerPriceValue, 30)
                : entryMarkPrice
            }
            posQty={posQty}
            posSize={posSize}
            onActiveTakeProfit={(val) => setIsTakeProfit(val)}
            onChaneTriggerPrice={(val) => setTakeProfitTriggerPrice(val)}
            onChangeCloseQuantity={(val) => setTakeProfitCloseQuantity(val)}
            show={isShowTakeProfit}
            setShow={setIsShowTakeProfit}
            takeProfitTriggerError={takeProfitTriggerError}
          />
          <div className="divider" />
          <StopLossCard
            isLong={isLong}
            marketSymbol={chartSymbol}
            marketPrice={
              orderOption === MARKET
                ? entryMarkPrice
                : orderOption === STOP_LIMIT
                ? triggerStopPriceValue
                  ? parseValue(triggerStopPriceValue, 30)
                  : entryMarkPrice
                : triggerPriceValue
                ? parseValue(triggerPriceValue, 30)
                : entryMarkPrice
            }
            posQty={posQty}
            liquidPrice={liquidPrice}
            onActiveStopLoss={(val) => setIsStopLoss(val)}
            onChaneTriggerPrice={(val) => setStopLossTriggerPrice(val)}
            onChangeCloseQuantity={(val) => setStopLossCloseQuantity(val)}
            triggerPrice={stopLossTriggerPrice}
            setTriggerPrices={setStopLossTriggerPrice}
            closeQuantity={stopLossCloseQuantity}
            setCloseQuantity={setStopLossCloseQuantity}
            show={isShowStopLoss}
            setShow={setIsShowStopLoss}
            stopLossTriggerError={stopLossTriggerError}
          />
          <div className="divider" />
          <div className="Exchange-swap-button-container">
            {/* {chainId === FANTOM ? (
              <button
                className={`App-cta Exchange-swap-button ${isShort ? "short-btn-v2" : "default-btn"}`}
                onClick={onClickPrimary}
                disabled={
                  !isPrimaryEnabled() ||
                  (active &&
                    ((isStopLoss && !!stopLossTriggerError) ||
                      (isTakeProfit && !!takeProfitTriggerError) ||
                      !!getErrorCollateral() ||
                      !Number(fromValue)))
                }
              >
                {getPrimaryText()}
              </button>
            ) : (
              <button
                className={`App-cta Exchange-swap-button default-btn`}
                onClick={() => switchNetwork(FANTOM, active)}
              >
                Switch to FANTOM
              </button>
            )} */}
            <button
              className={`App-cta Exchange-swap-button ${isShort ? "short-btn-v2" : "default-btn"}`}
              onClick={onClickPrimary}
              disabled={
                !isPrimaryEnabled() ||
                (active &&
                  ((isStopLoss && !!stopLossTriggerError) ||
                    (isTakeProfit && !!takeProfitTriggerError) ||
                    !!getErrorCollateral() ||
                    !Number(fromValue)))
              }
            >
              {getPrimaryText()}
            </button>
          </div>
        </div>
      </div>
      {isConfirming && (
        <ConfirmationBox
          library={library}
          isLong={isLong}
          orderOption={orderOption}
          posSize={posSize}
          posQty={posQty}
          markPrice={entryMarkPrice}
          openPosFee={posFees}
          liqPrice={liquidPrice}
          feeDiscount={feeDiscount}
          onConfirmationClick={onConfirmationClick}
          spread={spread?.value}
          setIsConfirming={setIsConfirming}
          chainId={chainId}
          leverage={leverageOption}
          isPendingConfirmation={isPendingConfirmation}
          isMarketOrder={isMarketOrder}
          isSubmitting={isSubmitting}
          isTakeProfit={isTakeProfit}
          isStopLoss={isStopLoss}
          takeProfitTriggerPrice={takeProfitTriggerPrice}
          stopLossTriggerPrice={stopLossTriggerPrice}
          savedSlippageAmount={savedSlippageAmount}
          setSavedSlippageAmount={setSavedSlippageAmount}
          chartSymbol={chartSymbol}
          isLimitOrder={isLimitOrder}
          limitPrice={isStopOrder || isStopLimitOrder ? triggerStopPriceValue : triggerPriceValue}
          isStopOrder={isStopOrder}
          stopPrice={triggerPriceValue}
          isStopLimitOrder={isStopLimitOrder}
          currentMarket={currentMarket}
        />
      )}
    </div>
  );
}
