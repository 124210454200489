import styled from "styled-components";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useCopyToClipboard } from "react-use";
// import { BiCopy } from "react-icons/bi";
import Modal from "components/Modal/Modal";

import { helperToast } from "lib/helperToast";
import { formatAmount } from "lib/numbers";
import copyIc from "img/copy.svg";
import copyIcLight from "img/coppy-light.svg";

import { decodeReferralCode } from "domain/referrals";

import { WrapperItem, TraderItemWrapper } from "../styles/Analytics.styled";

import icView from "img/ic-view.svg";
import { getReferralCodeTradeUrl } from "components/Referrals/referralsHelper";
import { useThemeContext } from "contexts/ThemeProvider";

const getPrize = (rank) => {
  return rank === 1 ? "7.5k FTM" : "4.5k FTM";
};

const ReferralsItem = ({ data, rank = 2, hasPrize, isThemeLight }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { lightThemeClassName } = useThemeContext();

  // const history = useHistory();

  const { address, discount, numOfTrade, referralCodes, reward, tier, totalReferralRegistered, volume } = data;

  const volumeReferred = formatAmount(volume, 30, 3, true);

  const rewards = formatAmount(reward, 30, 3, true);

  const [, copyToClipboard] = useCopyToClipboard();

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <WrapperItem className={lightThemeClassName}>
      <TraderItemWrapper to={`/analytic/account/${address}`}>
        {rank < 3 && hasPrize && <div className="ribbon">{getPrize(rank)}</div>}
        <div className="profile">
          <div className={`rank ${isThemeLight ? "light" : ""} ${rank < 4 ? "top" : ""} top-${rank}`}>
            {rank > 3 && rank}
          </div>
          <div className="detail">
            <div>
              <div>{address.slice(0, 6) + "..." + address.slice(address.length - 4, address.length)}</div>

              <div
                style={{
                  color: isThemeLight ? "rgba(13,26,22,.7)" : "#8b968c",
                  fontSize: 12,
                  marginTop: 5,
                }}
              >
                {referralCodes?.length === 1
                  ? decodeReferralCode(referralCodes[0].code)
                  : `${referralCodes?.length} Referral codes`}
              </div>
            </div>
          </div>
        </div>

        <div className="winLoss">
          {isSmallScreen ? (
            <div
              className="wrapper"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="label">Tier</div>
              <div className="value">
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  Tier {tier}
                </div>
                <div
                  style={{
                    color: isThemeLight ? "rgba(13,26,22,.7)" : "#8b968c",
                    fontSize: 12,
                    marginTop: 5,
                  }}
                >
                  {discount}% discount
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div>Tier {tier}</div>
              <div
                style={{
                  color: isThemeLight ? "rgba(13,26,22,.7)" : "#8b968c",
                  fontSize: 12,
                  marginTop: 5,
                }}
              >
                {discount}% discount
              </div>
            </div>
          )}
        </div>

        <div className="tradingVol">
          {isSmallScreen ? (
            <div
              className="wrapper"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="label">Traders Referred</div>
              <div className="value">{totalReferralRegistered}</div>
            </div>
          ) : (
            <div>{totalReferralRegistered}</div>
          )}
        </div>

        <div className="tradingVol">
          {isSmallScreen ? (
            <div
              className="wrapper"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="label">Number of Trades</div>
              <div className="value">{numOfTrade}</div>
            </div>
          ) : (
            <div>{numOfTrade}</div>
          )}
        </div>

        <div className="tradingVol">
          {isSmallScreen ? (
            <div
              className="wrapper"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="label">Total Volume Referred</div>
              <div className="value">${volumeReferred}</div>
            </div>
          ) : (
            <div>${volumeReferred}</div>
          )}
        </div>

        <div className="tradingVol">
          {isSmallScreen ? (
            <div
              className="wrapper"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="label">Rewards</div>
              <div className="value">${rewards}</div>
            </div>
          ) : (
            <div>${rewards}</div>
          )}
        </div>
        {referralCodes?.length === 1 ? (
          <div
            className="copy-code"
            onClick={(e) => {
              e.preventDefault();

              copyToClipboard(getReferralCodeTradeUrl(decodeReferralCode(referralCodes[0].code)));
              helperToast.success("Referral link copied to your clipboard");
            }}
          >
            Copy Code
          </div>
        ) : (
          <div
            className="copy-code"
            onClick={(e) => {
              e.preventDefault();
              setIsVisible(true);
            }}
          >
            View Codes
          </div>
        )}

        <img className="icon-view" src={icView} alt="icView" />
      </TraderItemWrapper>

      <Modal className="secondary" isVisible={isVisible} setIsVisible={setIsVisible} label={"Code List"}>
        <div className="header" style={{ justifyContent: "flex-end" }}>
          <div>Referral Code</div>
          <div>Volume</div>
          <div style={{ width: "20px" }}></div>
        </div>

        <StyledCodeWrapper>
          {referralCodes.map((item, index) => (
            <StyledCodeItem className="code-item-group">
              <span>{decodeReferralCode(item.code)}</span>

              <span>
                ${formatAmount(item.volume, 30, 3, true) === "0.000" ? 0 : formatAmount(item.volume, 30, 3, true)}
              </span>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  copyToClipboard(getReferralCodeTradeUrl(decodeReferralCode(item.code)));
                  helperToast.success("Referral link copied to your clipboard");
                }}
              >
                <img src={isThemeLight ? copyIcLight : copyIc} alt="" className="referral-code-icon" />
              </div>
            </StyledCodeItem>
          ))}
        </StyledCodeWrapper>
      </Modal>
    </WrapperItem>
  );
};

const StyledCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 452px;
  @media screen and (max-width: 700px) {
    max-width: 100%;
  }
`;

const StyledCodeItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10;
  margin-bottom: 5;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #ffffff1a;
  background-color: #ffffff1a;
  justify-content: flex-end;

  > span:nth-child(-n + 2) {
    flex: 1;
  }

  .referral-code-icon {
    width: 20px;
    height: 20px;
    pointer-events: all;
    cursor: pointer;
  }
`;

export default ReferralsItem;
