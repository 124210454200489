import { useChainIdWithDefault } from "lib/chains";
import { useEffect, useState } from "react";
import styled from "styled-components";

import ExternalLink from "components/ExternalLink/ExternalLink";
import Footer from "components/Footer/Footer";
import { ARBITRUM, BASE, FANTOM, OP } from "config/chains";
import { useThemeContext } from "contexts/ThemeProvider.js";
import imgHeader from "img/analytics/img-header.svg";
import rightImg from "img/analytics/right-img.svg";
import { TXT } from "pages/NFT/multichainTxt.js";
import { Link, Route, useLocation, useRouteMatch } from "react-router-dom";
import "styles/AnalyticV1.scss";
import SearchAddress from "./SearchAddress";
import Account from "./components/Account";
import LeaderBoard from "./components/LeaderBoard";
import Oracle from "./components/Oracle.jsx";
import Overview from "./components/Overview.js";
import PositionDetail from "./components/PositionDetail";
import TopPositionTab from "./components/TopPositionTab";
import TopReferrals from "./components/TopReferrals";
import TopTraderTab from "./components/TopTraderTab";
import { StyledAnalyticsContent } from "./styles/AnalyticsV3.styled";
import { getV1SubgraphData } from "utils/analyticsV3";
import useAnalyticsOverview from "hooks/analyticsV3/useAnalyticsOverview";
import { THEME_LIGHT } from "lib/legacy";
const NOW_TS = Math.floor(Date.now() / 1000);
const FIRST_DATE_TS = Math.floor(+new Date(2021, 7, 31) / 1000);
export default function AnalyticsV3() {
  const { isLightTheme } = useThemeContext() as any;
  const location = useLocation();
  const { path } = useRouteMatch();
  // const { chainId } = useChainId();
  const chainId = useChainIdWithDefault({
    isUseDefaultChain: false,
  });
  const [activedTab, setActivedTab] = useState(location.pathname);

  useEffect(() => {
    setActivedTab(location.pathname);
  }, [location.pathname]);

  const TABS = [
    {
      label: "Overview",
      networks: [FANTOM, OP, ARBITRUM, BASE],
      link: "/analytic/overview",
    },

    {
      label: "Top Traders",
      networks: [FANTOM, OP, ARBITRUM, BASE],
      link: "/analytic/top-traders",
    },
    {
      label: "Top Positions",
      networks: [FANTOM, OP, ARBITRUM, BASE],
      link: "/analytic/top-positions",
    },
    {
      label: "Top Referrals",
      networks: [FANTOM, OP, ARBITRUM, BASE],
      link: "/analytic/top-referrals",
    },
    {
      label: "Loyalty",
      networks: [], //TODO legacy
      link: "/analytic/loyalty",
    },
    {
      label: "Oracle",
      networks: [FANTOM, OP, ARBITRUM, BASE],
      link: "/analytic/oracle",
    },
    {
      label: "Search",
      networks: [FANTOM, OP, ARBITRUM, BASE],
      link: "/analytic/search",
    },
    // {
    //   label: "Contest",
    //   networks: [FANTOM, OP, ARBITRUM, BASE],
    //   link: "/analytic/contest",
    // },
  ];
  return (
    <div
      style={{
        backgroundColor: "#070912",
      }}
      className={`analytic-v1-wrapper ${isLightTheme ? `analytic-v1-wrapper--light ${THEME_LIGHT}` : ""}`}
    >
      <HeadWraper $isThemeLight={isLightTheme}>
        <div className="analytic-container header">
          <Title>
            <div className="title-container">
              <span className="title-head">Analytics</span> <img src={TXT[chainId].icon} alt="img" />{" "}
            </div>
            <p className="desc">The ranked list based on trading performance</p>
          </Title>
        </div>
      </HeadWraper>
      <Wrapper>
        <StyledAnalyticsContent>
          <Route path={`${path}/overview`}>
            <Overview />
          </Route>
          <Route path={`${path}/top-traders`}>
            <TopTraderTab chainId={chainId} isThemeLight={isLightTheme} />
          </Route>
          <Route path={`${path}/top-positions`}>
            <TopPositionTab chainId={chainId} isThemeLight={isLightTheme} />
          </Route>
          <Route path={`${path}/top-referrals`}>
            <TopReferrals chainId={chainId} isThemeLight={isLightTheme} />
          </Route>
          <Route path={`${path}/loyalty`}>
            <LeaderBoard chainId={chainId} />
          </Route>
          <Route path={`${path}/position/:position`}>
            <PositionDetail chainId={chainId} isThemeLight={isLightTheme} />
          </Route>
          <Route path={`${path}/account/:account`}>
            <Account chainId={chainId} isThemeLight={isLightTheme} />
          </Route>
          <Route path={`${path}/search`}>
            <SearchAddress />
          </Route>
          <Route path={`${path}/oracle`}>
            <Oracle chainId={chainId} />
          </Route>
          {/* <Route path={`${path}/contest`}>
            <Contest chainId={chainId} />
          </Route> */}
          {/* <Route path={`${path}/`}>
            <Overview />
          </Route> */}
          <Route exact path={`${path}/`}>
            <Overview />
          </Route>
        </StyledAnalyticsContent>

        <Footer />
      </Wrapper>
    </div>
  );
}
const Wrapper = styled.div`
  .analytic-container {
    width: 100%;
    max-width: calc(1320px + 48px);
    padding-left: 24px;
    padding-right: 24px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 700px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;
const TabWrapper = styled.div`
  width: 100%;
  max-width: calc(1320px + 48px);
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto 24px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-direction: column;
    padding-left: 20px;
  }

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;

    > .tab-container {
      width: 100%;
    }

    > span {
      padding-left: 20px;
      margin-top: 10px;
      width: 100% !important;
    }
  }

  > span {
    flex: 1 45%;
    text-align: right;
    align-self: flex-end;
    color: #ffffff99 !important;

    @media (max-width: 1023px) {
      margin-top: 16px;
      padding-right: 20px;
    }
  }

  .tab-container {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    padding: 4px;
    background-color: #191b2e;
    border-radius: 0px 0px 16px 16px;

    &::-webkit-scrollbar {
      height: 2px;
    }

    &::-webkit-scrollbar-track-piece:end {
      margin-right: 10px;
    }

    &::-webkit-scrollbar-track-piece:start {
      margin-left: 10px;
    }

    @media (max-width: 1023px) {
      justify-content: space-between;
    }
  }
`;
const TabItem = styled(Link)`
  padding: 12px 16px;
  white-space: nowrap;

  font-size: 14px;
  text-decoration: none;
  font-weight: 500 !important;
  color: rgb(255, 255, 255);
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: #04d397 !important;
  }
  &.tab--actived {
    color: #03f5ae !important;
    background-color: #ffffff1a;
    border-radius: 12px;
  }
`;
const Title = styled.div`
  width: 1218px;
  max-width: 100%;
  text-transform: uppercase;
  align-self: flex-start;
  margin-top: 64px;

  @media (max-width: 767px) {
    padding-bottom: 24px;
  }

  .title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    @media (max-width: 1023px) {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  span {
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    color: rgb(255, 255, 255);

    @media (max-width: 1023px) {
      font-size: 24px;
      line-height: 140%;
    }
  }
  .version-styled {
    color: #03f5ae !important;
  }

  img {
    width: 48px;
    height: 48px;
  }

  .desc {
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.6) !important;
    margin-top: 4px;
    margin-bottom: 0;
  }
`;
const HeadWraper = styled.div<{ $isThemeLight: Boolean }>`
  width: 100%;
  overflow: hidden;
  position: relative;
  @media (max-width: 767px) {
    height: unset;
  }

  .header {
    width: 100%;
    max-width: calc(1320px + 48px);
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
    display: flex;
    position: relative;

    justify-content: space-between;
    align-items: flex-end;
    height: 100%;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
`;
const ImgContainer = styled.div`
  width: 376px;
  height: 225px;
  overflow: hidden;
  object-fit: contain;
  margin-left: auto;
  flex-shrink: 0;

  @media screen and (max-width: 767px) {
    height: 160px;

    img {
      height: 100%;
    }
  }
`;
